import Tippy from "@tippyjs/react";
import {PopoverIcon} from "../../../../assets/icons";
import styles from './ConditionsComponent.module.scss';
import useBasisFuncs from "../../../../hooks/useBasisFuncs";
import getNameGenerator from "../../../../hooks/useNameGenerator";
import {AutoGrowHeightTextarea} from "../../AutoGrowHeightTextarea";
import Tooltip from "../../../UI/Tooltip";
import UseIsMobile from "@hooks/UseIsMobile";

const ConditionsComponent = ({setDisableSaveButtons, activeCondition, setActiveCondition, conditionItems, setConditionItems, newConditionItems, setNewConditionItems, preOwnedItems, setPreOwnedItems,formInfo, unescapedRowMetadata, listInfo, handleStandartInputChange, itemData, editIndex, setItemData }:any) => {
    const { bdecode } = useBasisFuncs();
    const isMobile = UseIsMobile() ;
    const toggleConditionsItems = (id: number, value: any) => {
        setDisableSaveButtons(false);
        setActiveCondition(id);
        let updatedItems: any = [...itemData];
        const generatedName = getNameGenerator('input_15');
        updatedItems[editIndex] = {
            ...updatedItems[editIndex],
            [generatedName]: value,
            changed: true,
        };

        const generatedName2 = getNameGenerator('input_795');
        updatedItems[editIndex] = {
            ...updatedItems[editIndex],
            [generatedName2]: null,
            changed: true,
        };

        const generatedName3 = getNameGenerator('input_796');
        updatedItems[editIndex] = {
            ...updatedItems[editIndex],
            [generatedName3]: null,
            changed: true,
        };

        setItemData(updatedItems);
        setConditionItems((prevItems:any) => {
            const updatedItems = prevItems.map((item:any) => ({
                ...item,
                active: item.id === id && !item.active,
            }));

            const clickedItem = updatedItems.find((item:any) => item.id === id);
            if (clickedItem && clickedItem.active) {
                return updatedItems;
            }
            return updatedItems.map((item:any) => ({
                ...item,
                active: item.id === id,
            }));
        });

        setNewConditionItems((prevItems:any) =>
            prevItems.map((item:any, index:any) => ({
                ...item,
                active: index === prevItems.length - 1, // Set active to true only for the last element
            }))
        );
        setPreOwnedItems((prevItems:any) =>
            prevItems.map((item:any, index:any) => ({
                ...item,
                active: index === prevItems.length - 1, // Set active to true only for the last element
            }))
        );

    };
    const togglePreOwnedItems = (id: number, value: any) => {
        setDisableSaveButtons(false);
        let updatedItems: any = [...itemData];
        const generatedName = getNameGenerator('input_796');
        updatedItems[editIndex] = {
            ...updatedItems[editIndex],
            [generatedName]: value,
            changed: true,
        };
        setItemData(updatedItems);
        setPreOwnedItems((prevItems:any) => {
            const updatedItems = prevItems.map((item:any) => ({
                ...item,
                active: item.id === id && !item.active,
            }));

            const clickedItem = updatedItems.find((item:any) => item.id === id);
            if (clickedItem && clickedItem.active) {
                return updatedItems;
            }
            return updatedItems.map((item:any) => ({
                ...item,
                active: item.id === id,
            }));
        });
    };
    const toggleNewConditionsItems = (id: number, value: any) => {
        setDisableSaveButtons(false);
        let updatedItems: any = [...itemData];
        const generatedName = getNameGenerator('input_795');
        updatedItems[editIndex] = {
            ...updatedItems[editIndex],
            [generatedName]: value,
            changed: true,
        };
        setItemData(updatedItems);
        setPreOwnedItems((prevItems:any) =>
            prevItems.map((item:any) => ({
                ...item,
                active: item.id === id ? !item.active : false,
            }))
        );
        setNewConditionItems((prevItems:any) => {
            const updatedItems = prevItems.map((item:any) => ({
                ...item,
                active: item.id === id && !item.active,
            }));

            const clickedItem = updatedItems.find((item:any) => item.id === id);
            if (clickedItem && clickedItem.active) {
                return updatedItems;
            }
            return updatedItems.map((item:any) => ({
                ...item,
                active: item.id === id,
            }));
        });
    };

    return(
        <div>
            <div className={styles.intendedMenu}>
                <p className={styles.title}>
                    Condition
                    <Tippy
                        interactive={true}
                        zIndex={5}
                        trigger="click"
                        arrow={false}
                        content={
                            <div className={`popover ${styles.popoverContent}`}>
                                <ul>
                                    <li>Select your product's condition.</li>

                                    <div className={styles.line}></div>
                                    <p>★ Crossposts to supported 'condition' fields.</p>
                                    <p>
                                        ♦ Added to Pro{' '}
                                        <a
                                            href="https://help.listperfectly.com/en/articles/8908753-list-perfectly-s-description-builder-pro-pro-plus"
                                            target="_blank"
                                        >
                                            {' '}
                                            Description Builder
                                        </a>
                                    </p>
                                </ul>
                            </div>
                        }
                    >
                        <PopoverIcon
                            className={styles.popoverIcon}
                            color={'#fff'}
                        ></PopoverIcon>
                    </Tippy>
                </p>
                <div className={styles.conditionMenu}>
                    <div
                        className={`${styles.menuItems} ${styles.menuItemsConditions}`}
                    >
                        {conditionItems.map((item: any) => (
                            <div
                                data-name="input_15"
                                className={
                                    item.active ? styles.itemActive : styles.item
                                }
                                key={item.id}
                                onClick={() =>
                                    toggleConditionsItems(item.id, item.value)
                                }
                            >
                                <span>{item.text}</span>
                            </div>
                        ))}
                    </div>
                    <div className={styles.conditionsTextarea}>
                        <p className={styles.title}>
                            Condition Notes
                        </p>
                        <AutoGrowHeightTextarea
                            placeholder="Condition Notes"
                            name="input_17"
                            value={
                                formInfo.newConditionNotes.changed
                                    ? bdecode(formInfo.newConditionNotes.text)
                                    : unescapedRowMetadata[17]
                                        ? bdecode(unescapedRowMetadata[17])
                                        : ''
                            }
                            handleChange={handleStandartInputChange}
                            limit={30000}
                            hasLimit={false}
                            height={100}
                        />
                    </div>
                </div>
            </div>
            {activeCondition === 1 && (
                <div className={styles.intendedMenu}>
                    <p className={styles.title}>New Condition</p>
                    <div className={styles.menuItems}>
                        {newConditionItems.map((item: any) => {
                            const newConditionContentBlock = (
                             <div
                              data-name="input_795"
                              className={item.active ? styles.itemActive : styles.item}
                              key={item.id}
                              onClick={() => toggleNewConditionsItems(item.id, item.value)}
                             >
                                 <span>{item.text}</span>
                             </div>
                            );

                            return item.value && !isMobile ? (
                             <Tooltip trigger="mouseenter" content={<div>{item.tooltipValue}</div>}>
                                 {newConditionContentBlock}
                             </Tooltip>
                            ) : (
                             newConditionContentBlock
                            );
                        })}
                    </div>
                </div>
            )}
            {activeCondition === 2 && (
             <div className={styles.intendedMenu}>
                 <p className={styles.title}>
                     Pre-Owned Condition
                     <Tippy
                      interactive={true}
                      zIndex={5}
                      trigger="mouseenter focus click"
                      arrow={false}
                      content={
                          <div className={`popover ${styles.popoverContent}`}>
                                    <p>
                                        These selections will be shown in your description.
                                    </p>
                                    <p>Example: CONDITION: Pre-owned Like New</p>
                                    <p>
                                        Soon we will add marketplace support for these
                                        selections where applicable.
                                    </p>
                                </div>
                            }
                        >
                            <PopoverIcon
                                className={styles.popoverIcon}
                                color={'#fff'}
                            ></PopoverIcon>
                        </Tippy>
                    </p>
                    <div className={styles.menuItems}>
                        {preOwnedItems.map((item:any) => (
                            <div
                                data-name="input_796"
                                className={
                                    item.active ? styles.itemActive : styles.item
                                }
                                key={item.id}
                                onClick={() => togglePreOwnedItems(item.id, item.value)}
                            >
                                <span>{item.text}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}


export default ConditionsComponent;