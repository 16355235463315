import React, { useState, forwardRef, useRef, useEffect } from 'react';

import { Popover } from 'react-tiny-popover';

import styles from '../../Table.module.scss';
import { ICatalogColumn } from '../../../../../../interfaces';
import {
  LockIcon,
  SVGClearSort,
  SVGNewAsc,
  SVGNewDesc,
} from '../../../../../../assets/icons';
import ColumnSearch from '../ColumnSearch';
import Tippy from "@tippyjs/react";

interface ISortData {
  id: number;
  title: string;
}

const sortData: ISortData[] = [
  {
    id: Date.now(),
    title: 'ascending',
  },
  {
    id: Date.now(),
    title: 'descending',
  },
  {
    id: Date.now(),
    title: 'clear Sort',
  },
];

interface ItableResizeColumnProps {
  col: ICatalogColumn;
  id: string;
  handleChangeDirection: (
    columnId: string,
    direction: 'top' | 'bottom' | 'none'
  ) => void;
  resizableElementWidth: number;
  setResizableElementWidth: (width: number) => void;
  innerRef: any;
  activeIndex: number | null;
  i: number;
  mouseDown: (index: number) => void;
  directions: { [columnId: string]: 'top' | 'bottom' | 'none' };
  filterByArr: any;
  handleTogglePlatformsModalShow: any;
  activePlatforms: any;
  inputValues: any;
  handleInputChange: any;
  handleToggleDateRangeModalShow:any;
  dateRangeModalShow?:any;
  startChangedCreated?:any;
  endChangedCreated?:any;
  startDateCreated?:any;
  endDateCreated?:any;
  setStartChangedCreated?:any;
  setEndChangedCreated?:any;
  setStartDateCreated?:any;
  setEndDateCreated?:any;
  createdTippy?:any;
  setCreatedCloseClicked?:any;
  stickyColumnsList?:any;
  setStickyColumnsList?:any;
  getStickyStyle?:any;
  tippyElementStickyColumnsRef?:any;
  selectedCondition?:any
  dropDownChangeCondition?:any;
  optionsCondition?:any;
  newOptions?:any;
  selectedSubCondition?:any
  setSelectedSubCondition?:any;
  preOwnedOptions?:any;
}


const TableResizeColumn = forwardRef((props: ItableResizeColumnProps, ref) => {
  const {
    col,
    handleChangeDirection,
    id,
    resizableElementWidth,
    setResizableElementWidth,
    innerRef,
    activeIndex,
    i,
    mouseDown,
    directions,
    filterByArr,
    handleTogglePlatformsModalShow,
    activePlatforms,
    inputValues,
    handleInputChange,
    handleToggleDateRangeModalShow,
    dateRangeModalShow,
    startChangedCreated,
    endChangedCreated,
    startDateCreated,
    endDateCreated,
    setStartChangedCreated,
    setEndChangedCreated,
    setStartDateCreated,
    setEndDateCreated,
    createdTippy,
    setCreatedCloseClicked,
    stickyColumnsList,
    setStickyColumnsList,
    getStickyStyle,
    tippyElementStickyColumnsRef,
    selectedCondition,
    dropDownChangeCondition,
    optionsCondition,
    newOptions,
    selectedSubCondition,
    setSelectedSubCondition,
    preOwnedOptions,
  } = props;

  const sortRef = useRef<HTMLDivElement | null>(null);
  const [sortTollTipShow, setSortToolTipShow] = useState<boolean>(false);


  const handleToggleSortToolTipShow = () => {
    setSortToolTipShow(!sortTollTipShow);
  };

  const direction = directions[id] || 'none';

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (sortRef.current && !sortRef.current?.contains(event.target as Node)) {
        setSortToolTipShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sortRef, setSortToolTipShow]);

  useEffect(() => {
  try{
    const savedName = localStorage.getItem("activeColumnName");

    if (savedName) {
      // Update the state based on the saved name
      setStickyColumnsList((prevList: any) => {
        // Find the index of the active column
        const activeIndex = prevList.findIndex(
            (item: any) => item.value?.toLowerCase() === savedName.toLowerCase()
        );

        // Map over the list to update activeColumn and stickyColumn
        return prevList.map((item: any, index: number) => ({
          ...item,
          activeColumn: index === activeIndex,
          stickyColumn: index <= activeIndex, // Make all previous and active columns sticky
        }));
      });
    }
  }catch(e){
    console.log(e, 'error');
  }
  }, []);

  function updateActiveColumn(name: any) {
   try {
    // Retrieve the currently active column name from localStorage
    const currentActiveColumn = localStorage.getItem("activeColumnName");

    // Determine if the clicked item is already active
    const isCurrentlyActive = currentActiveColumn?.toLowerCase() === name?.toLowerCase();

    if (isCurrentlyActive) {
      // If the clicked item is already active, deactivate it
      localStorage.removeItem("activeColumnName");
      setStickyColumnsList((prevList: any) =>
          prevList.map((item: any) => ({
            ...item,
            activeColumn: false,
            stickyColumn: false,
          }))
      );
    } else {
      // If the clicked item is not active, activate it
      localStorage.setItem("activeColumnName", name);

      setStickyColumnsList((prevList: any) => {
        // Find the index of the clicked item
        const activeIndex = prevList.findIndex(
            (item: any) => item.value?.toLowerCase() === name?.toLowerCase()
        );

        // Update the stickyColumn and activeColumn properties
        return prevList.map((item: any, index: number) => ({
          ...item,
          activeColumn: index === activeIndex,
          stickyColumn: index <= activeIndex, // Set stickyColumn true for active and preceding columns
        }));
      });
    }
    }catch(e){
      console.log(e, 'error')
    }
  }


  return (
    <>
      {col.value !== 'actions' ? (
        col.value === 'drafts' ? (
          <th
            ref={innerRef}
            className="sticky-element"
            id={`${stickyColumnsList.some(
                (obj: any) =>
                    obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                    obj.stickyColumn
            ) ? 'sticky-image-col' : ''}`}
            style={{
              ...getStickyStyle(col.value), // Dynamic styles
              ...{
                position: 'sticky', top: '0'
              },
            }}
          >
            {
              stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === col.value?.toLowerCase()) ?
                  <div className="th-inner-container">
                    <div
                        className={`lock-icon ${
                            stickyColumnsList.some(
                                (obj: any) =>
                                    obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                                    obj.activeColumn
                            )
                                ? 'sticky-column-active' // Replace 'sticky-class' with your desired class name
                                : ''
                        }`}
                        onClick={()=> updateActiveColumn('drafts') }
                    >
                      {
                          !stickyColumnsList.some(
                              (obj: any) =>
                                  obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                                  obj.activeColumn
                          ) ?
                              <Tippy
                                  interactive={true}
                                  zIndex={9993}
                                  trigger="mouseenter"
                                  arrow={false}
                                  appendTo={tippyElementStickyColumnsRef.current || document.body}
                                  placement="bottom"
                                  content={
                                    <div>
                                        Pin this column and all columns to its left to keep them in view while scrolling.
                                    </div>
                                  }
                              >
                                <LockIcon/>
                              </Tippy> :
                              <Tippy
                                  interactive={true}
                                  zIndex={9993}
                                  trigger="mouseenter"
                                  arrow={false}
                                  appendTo={tippyElementStickyColumnsRef.current || document.body}
                                  placement="bottom"
                                  content={
                                    <div>
                                      Click to unpin all pinned columns.
                                    </div>
                                  }
                              >
                                <LockIcon/>
                              </Tippy>
                      }
                    </div>
                    <p> draft </p>
                  </div> :
                  <p> draft </p>
            }

            <div
              style={{
                height: '100%',
                position: 'absolute',
                zIndex: 534534,
              }}
              onMouseDown={() => mouseDown(i)}
              className={`resize-handle ${
                activeIndex === i ? 'active' : 'idle'
              }`}
            />
          </th>
        ) : col.value === 'issues' ? (
            <th
                ref={innerRef}
                className="sticky-element"
                id={`${stickyColumnsList.some(
                    (obj: any) =>
                        obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                        obj.stickyColumn
                ) ? 'sticky-image-col' : ''}`}
                style={{
                  ...getStickyStyle(col.value), // Dynamic styles
                  ...{
                    position: 'sticky', top: '0', background: '#F4F7FF'
                  },
                }}
            >
              {
                stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === col.value?.toLowerCase()) ?
                    <div className="th-inner-container">
                      <div
                          className={`lock-icon ${
                              stickyColumnsList.some(
                                  (obj: any) =>
                                      obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                                      obj.activeColumn
                              )
                                  ? 'sticky-column-active' // Replace 'sticky-class' with your desired class name
                                  : ''
                          }`}
                          onClick={()=> updateActiveColumn(col.value) }
                      >
                        {
                          !stickyColumnsList.some(
                              (obj: any) =>
                                  obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                                  obj.activeColumn
                          ) ?
                              <Tippy
                                  interactive={true}
                                  zIndex={9993}
                                  trigger="mouseenter"
                                  arrow={false}
                                  appendTo={tippyElementStickyColumnsRef.current || document.body}
                                  placement="bottom"
                                  content={
                                    <div>
                                        Pin this column and all columns to its left to keep them in view while
                                        scrolling.
                                    </div>
                                  }
                              >
                                <LockIcon/>
                              </Tippy> :
                              <Tippy
                                  interactive={true}
                                  zIndex={9993}
                                  trigger="mouseenter"
                                  arrow={false}
                                  appendTo={tippyElementStickyColumnsRef.current || document.body}
                                  placement="bottom"
                                  content={
                                    <div>
                                      Click to unpin all pinned columns.
                                    </div>
                                  }
                              >
                                <LockIcon/>
                              </Tippy>
                        }
                      </div>
                      <p> {col.value} </p>
                    </div> :
                    <p> {col.value} </p>
              }

              <div
                  style={{
                    height: '100%',
                    position: 'absolute',
                    zIndex: 534534,
                  }}
                  onMouseDown={() => mouseDown(i)}
                  className={`resize-handle ${
                      activeIndex === i ? 'active' : 'idle'
                  }`}
              />
            </th>
        ) : (
            <th
                ref={innerRef}
                className="sticky-element"
                id={`${stickyColumnsList.some(
                    (obj: any) =>
                        obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                        obj.stickyColumn
                ) ? 'sticky-image-col' : ''}`}
                style={{
                  ...getStickyStyle(col.value), // Dynamic styles
                  ...{
                    position: 'sticky',
                    top: '0',
                    background: '#F4F7FF',
                  },
                }}
            >
            {col.value === 'keywords' ||
            col.value === 'platforms' ||
            col.value === 'description' ||
            col.value === 'date sold' ||
            col.value === 'fees' ||
            col.value === 'assignee' ||
            col.value === 'sold price' ||
            col.value === 'shipping cost' ||
            col.value === 'profit' ||
            col.value === 'cogs' ||
            col.value === 'upc' ||
            col.value === 'brand' ||
            col.value === 'color' ||
            col.value === 'size' ||
            col.value === 'material' ||
            col.value === 'condition' ||
            col.value === 'condition notes' ||
            col.value === 'condition 2' ||col.value === 'notes' || col.value ==='sales' || col.value === 'zip' || col.value === 'shipping package length' || col.value === 'shipping package height'   || col.value === 'shipping package width'   || col.value === 'shipping weight oz/g' || col.value === 'shipping weight oz/g' || col.value === 'shipping weight lb/kg' ? (
                <span className={styles.flexHeaderOptimizer}>
                  {
                    stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === col.value?.toLowerCase()) ?
                        <div className="th-inner-container">
                          <div
                              className={`lock-icon ${
                                  stickyColumnsList.some(
                                      (obj: any) =>
                                          obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                                          obj.activeColumn
                                  )
                                      ? 'sticky-column-active' // Replace 'sticky-class' with your desired class name
                                      : ''
                              }`}
                              onClick={()=> updateActiveColumn(col.value) }>
                            {
                              !stickyColumnsList.some(
                                  (obj: any) =>
                                      obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                                      obj.activeColumn
                              ) ?
                                  <Tippy
                                      interactive={true}
                                      zIndex={9993}
                                      trigger="mouseenter"
                                      arrow={false}
                                      appendTo={tippyElementStickyColumnsRef.current || document.body}
                                      placement="bottom"
                                      content={
                                        <div>
                                            Pin this column and all columns to its left to keep them in view while
                                            scrolling.
                                        </div>
                                      }
                                  >
                                    <LockIcon/>
                                  </Tippy> :
                                  <Tippy
                                      interactive={true}
                                      zIndex={9993}
                                      trigger="mouseenter"
                                      arrow={false}
                                      appendTo={tippyElementStickyColumnsRef.current || document.body}
                                      placement="bottom"
                                      content={
                                        <div>
                                          Click to unpin all pinned columns.
                                        </div>
                                      }
                                  >
                                    <LockIcon/>
                                  </Tippy>
                            }
                          </div>
                          <p>{col.heading}</p>
                        </div> :
                        <p>{col.heading}</p>
                  }

                  {col.value === 'platforms'
                      ? null
                      : direction !== 'none' && (
                      <div className={styles.selectedSortView}>
                        {direction === 'bottom' ? (
                            <div className={styles.caretUp}>
                              <SVGNewDesc/>
                            </div>
                        ) : (
                            <SVGNewAsc/>
                        )}
                      </div>
                  )}
              </span>
            ) : (
                <>
                {
                  stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === col.value?.toLowerCase()) ?
                      <div className="th-inner-container">
                        <div
                            className={`lock-icon ${
                                stickyColumnsList.some(
                                    (obj: any) =>
                                        obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                                        obj.activeColumn
                                )
                                    ? 'sticky-column-active' // Replace 'sticky-class' with your desired class name
                                    : ''
                            }`}
                            onClick={()=> updateActiveColumn(col.value) }>
                          {
                            !stickyColumnsList.some(
                                (obj: any) =>
                                    obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                                    obj.activeColumn
                            ) ?
                                <Tippy
                                    interactive={true}
                                    zIndex={9993}
                                    trigger="mouseenter"
                                    arrow={false}
                                    appendTo={tippyElementStickyColumnsRef.current || document.body}
                                    placement="bottom"
                                    content={
                                      <div>
                                          Pin this column and all columns to its left to keep them in view while
                                          scrolling.
                                      </div>
                                    }
                                >
                                <LockIcon/>
                                </Tippy> :
                                <Tippy
                                    interactive={true}
                                    zIndex={9993}
                                    trigger="mouseenter"
                                    arrow={false}
                                    appendTo={tippyElementStickyColumnsRef.current || document.body}
                                    placement="bottom"
                                    content={
                                      <div>
                                        Click to unpin all pinned columns.
                                      </div>
                                    }
                                >
                                  <LockIcon/>
                                </Tippy>
                          }
                        </div>
                        <Popover
                            isOpen={sortTollTipShow}
                            positions={['bottom']}
                            reposition={true}
                            padding={15}
                            containerStyle={{zIndex: '999'}}
                            content={
                              sortTollTipShow ? (
                                  <div className="popover popoverCatalog" ref={sortRef}>
                                    {sortData.map((item) => (
                                        <div className={styles.popOverWrapper}>
                                          <div
                                              className={styles.popoverSortItem}
                                              key={item.id}
                                              onClick={() => {
                                                handleChangeDirection(
                                                    col.value,
                                                    item.title === 'clear Sort'
                                                        ? 'none'
                                                        : item.title === 'ascending'
                                                            ? 'top'
                                                            : 'bottom'
                                                );

                                                handleToggleSortToolTipShow();
                                              }}
                                          >
                                            <div
                                                className={styles['popoverSortItemIcon']}
                                            >
                                              {item.title === 'clear Sort' ? (
                                                  <SVGClearSort />
                                              ) : item.title === 'descending' ? (
                                                  <SVGNewDesc />
                                              ) : (
                                                  <SVGNewAsc />
                                              )}
                                            </div>
                                            <div>{item.title}</div>
                                          </div>
                                        </div>
                                    ))}
                                  </div>
                              ) : (
                                  <div></div>
                              )
                            }
                        >
                <span
                    onClick={handleToggleSortToolTipShow}
                    className={styles.flexHeaderOptimizer}
                >
                  <p>{col.heading}</p>

                  {col.value === 'platforms'
                      ? null
                      : direction !== 'none' && (
                      <div className={styles.selectedSortView}>
                        {direction === 'bottom' ? (
                            <div className={styles.caretUp}>
                              <SVGNewAsc />
                            </div>
                        ) : (
                            <SVGNewDesc />
                        )}
                      </div>
                  )}
                </span>
                        </Popover>
                      </div> :
                      <Popover
                          isOpen={sortTollTipShow}
                          positions={['bottom']}
                          reposition={true}
                          padding={15}
                          containerStyle={{zIndex: '999'}}
                          content={
                            sortTollTipShow ? (
                                <div className="popover popoverCatalog" ref={sortRef}>
                                  {sortData.map((item) => (
                                      <div className={styles.popOverWrapper}>
                                        <div
                                            className={styles.popoverSortItem}
                                            key={item.id}
                                            onClick={() => {
                                              handleChangeDirection(
                                                  col.value,
                                                  item.title === 'clear Sort'
                                                      ? 'none'
                                                      : item.title === 'ascending'
                                                          ? 'top'
                                                          : 'bottom'
                                              );

                                              handleToggleSortToolTipShow();
                                            }}
                                        >
                                          <div
                                              className={styles['popoverSortItemIcon']}
                                          >
                                            {item.title === 'clear Sort' ? (
                                                <SVGClearSort />
                                            ) : item.title === 'descending' ? (
                                                <SVGNewDesc />
                                            ) : (
                                                <SVGNewAsc />
                                            )}
                                          </div>
                                          <div>{item.title}</div>
                                        </div>
                                      </div>
                                  ))}
                                </div>
                            ) : (
                                <div></div>
                            )
                          }
                      >
                <span
                    onClick={handleToggleSortToolTipShow}
                    className={styles.flexHeaderOptimizer}
                >
                  <p>{col.heading}</p>

                  {col.value === 'platforms'
                      ? null
                      : direction !== 'none' && (
                      <div className={styles.selectedSortView}>
                        {direction === 'bottom' ? (
                            <div className={styles.caretUp}>
                              <SVGNewAsc />
                            </div>
                        ) : (
                            <SVGNewDesc />
                        )}
                      </div>
                  )}
                </span>
                      </Popover>
                }
                </>
            )}

            <div
              style={{
                height: '100%',
                position: 'absolute',
                zIndex: 534534,
              }}
              onMouseDown={() => mouseDown(i)}
              className={`resize-handle ${
                activeIndex === i ? 'active' : 'idle'
              }`}
            />
            {col.value !== 'image' &&
              col.value !== 'updated' &&
              col.value !== 'drafts' &&
              col.value !== 'actions' &&
              col.value !== 'keywords' &&
              col.value !== 'date sold' &&
              col.value !== 'size' &&
              col.value !== 'sold price' &&
              col.value !== 'profit' &&
              col.value !== 'assignee' &&
              col.value !== 'shipping cost' &&
              col.value !== 'brand' &&
              col.value !== 'material' &&
              col.value !== 'cogs' &&
              col.value !== 'color' &&
              col.value !== 'condition notes' &&
              col.value !=='sales' && col.value !== 'shipping package width' && col.value !== 'shipping package length'
             && col.value !== 'shipping package height' && col.value !== 'shipping package height' && col.value !== 'shipping weight lb/kg' &&
             col.value !== 'shipping weight oz/g' &&
             col.value !== 'zip' &&
             (
                <ColumnSearch
                  filterByArr={filterByArr}
                  columnValue={col.value}
                  handleTogglePlatformsModalShow={
                    handleTogglePlatformsModalShow
                  }
                  activePlatforms={activePlatforms}
                  inputValues={inputValues}
                  handleInputChange={handleInputChange}
                  createdTippy={createdTippy}
                  startChangedCreated={startChangedCreated}
                  endChangedCreated={endChangedCreated}
                  startDateCreated={startDateCreated}
                  endDateCreated={endDateCreated}
                  setStartChangedCreated={setStartChangedCreated}
                  setEndChangedCreated={setEndChangedCreated}
                  setStartDateCreated={setStartDateCreated}
                  setEndDateCreated={setEndDateCreated}
                  dateRangeModalShow={dateRangeModalShow}
                  handleToggleDateRangeModalShow={handleToggleDateRangeModalShow}
                  setCreatedCloseClicked={setCreatedCloseClicked}
                  selectedCondition={selectedCondition}
                  dropDownChangeCondition={dropDownChangeCondition}
                  optionsCondition={optionsCondition}
                  newOptions={newOptions}
                  selectedSubCondition={selectedSubCondition}
                  setSelectedSubCondition={setSelectedSubCondition}
                  preOwnedOptions={preOwnedOptions}
                />
              )}
          </th>
        )
      ) : (
          <th
              ref={innerRef}
              className="sticky-element"
              style={{
                ...getStickyStyle(col.value), // Dynamic styles
                ...{
                  position: 'sticky', top: '0', background: '#F4F7FF'
                },
              }}
              id={`${stickyColumnsList.some(
                  (obj: any) =>
                      obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                      obj.stickyColumn
              ) ? 'sticky-image-col' : ''}`}
          >
            {
              stickyColumnsList.some((obj: any) => obj.value?.toLowerCase() === col.value?.toLowerCase()) ?
                  <div className="th-inner-container">
                    <div
                        className={`lock-icon ${
                            stickyColumnsList.some(
                                (obj: any) =>
                                    obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                                    obj.activeColumn
                            )
                                ? 'sticky-column-active' // Replace 'sticky-class' with your desired class name
                                : ''
                        }`}
                        onClick={()=> updateActiveColumn(col.value) }>
                      {
                        !stickyColumnsList.some(
                            (obj: any) =>
                                obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                                obj.activeColumn
                        ) ?
                        <Tippy
                            interactive={true}
                            zIndex={9993}
                            trigger="mouseenter"
                            arrow={false}
                            appendTo={tippyElementStickyColumnsRef.current || document.body}
                            placement="bottom"
                            content={
                              <div>
                                  Pin this column and all columns to its left to keep them in view while scrolling.
                              </div>
                            }
                        >
                        <LockIcon/>
                        </Tippy> :
                            <Tippy
                                interactive={true}
                                zIndex={9993}
                                trigger="mouseenter"
                                arrow={false}
                                appendTo={tippyElementStickyColumnsRef.current || document.body}
                                placement="bottom"
                                content={
                                  <div>
                                    Click to unpin all pinned columns.
                                  </div>
                                }
                            >
                              <LockIcon/>
                            </Tippy>
                      }
                    </div>
                    <p> {col.value} </p>
                  </div> :
                  <p> {col.value} </p>
            }

            <div
                style={{
                  height: '100%',
                  position: 'absolute',
                  zIndex: 534534,
                }}
                onMouseDown={() => mouseDown(i)}
                className={`resize-handle ${activeIndex === i ? 'active' : 'idle'}`}
            />
          </th>
      )}
    </>
  );
});

export default TableResizeColumn;
