import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import Tippy from "@tippyjs/react";
import {toast} from "react-toastify";

import styles from './SalesPage.module.scss';
import './SalesPage.css';

import {instanceAxios} from '../../services';
import {tableDataTransform} from '../../utils/tableDataTransform';
import {platforms} from './components/Table/data/platformsData'; // Marketplaces modal
import {CustomizeIcon, MarketIcon, PrintIcon} from '../../assets/icons';
import Iframe from '../../components/UI/Iframe';
import NotificationsModal from '../../components/UI/NotificationsModal';

import {
	setCancelToken,
	setDataForPrint,
	setIsMobile, setListingViewId,
	setRemoveQuestionModal,
	setReplaceCandidat,
	setResetCandidatLink,
	setResetPureCandidantLink, setShowListingViewModal,
} from '../../redux/slices/NavigationSlice';
import convertToSlug from '../../utils/convertToSlug';
import formatString from '../../utils/formatString';
import PerPageSelect from '../shared/PerPageSelect';
import {getAllDataByPayloadByPrint} from './components/Giver'; // Pick list
import PrintLoader from './components/PrintLoader';
import ReactDatePicker from './components/ReactDatePicker';
import Table from './components/Table';
import TablePagination from './components/Table/components/TablePagination';
import TableEditColumn from './components/TableEditColumn';

import Loader from '../../components/Templates/Loader';
import useDebounce from '../../hooks/UseDebounce';

import {setIsCancelSales, setIsSaveSales} from "../../redux/slices/SalesSlice";
import {skuSortListings} from "../../utils/skuSortListings";
import PaginateMessageModal from "../../components/UI/PaginateMessageModal";
import ConnectedMarketplacesModal from "../../components/ConnectedMarketplacesModal";
import {setRoles} from "../../redux/slices/RoleSlice";
import {setIsSave} from "../../redux/slices/CatalogSlice";
import ResultsFor from "./components/ResultsFor";
import {Helmet} from "react-helmet";

// Importing services
import AuthService from '@services/Auth';
import OrdersService from '@services/Orders';
// import ListingsService from '@services/Listings';
import Stats from "./components/Stats";
import json_parse_safe_object from "../../utils/json_parse_safe_object";
import Button, {ModifierType} from "../../components/UI/Button";
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";
import useRoles from "@hooks/useRoles";
import ListingViewModal from "../../components/UI/ListingViewModal";
import EditAndAddListingModal from "../../components/EditAddListingModal";

import DownloadCSV from "./components/DownloadCSV";
import CsvDownloadProgressBar from "../../components/UI/CsvDownloadProgressBar";
import Auth from "@services/Auth";

const SalesPage = () => {
	const { roles, hasBetaRole, is_role_allowed_master, hasBusiness, hasSimple, hasPro, hasSubRole } = useRoles();
	const isSmallScreenLaptop = UseIsSmallLaptop() ;
	const [tableModal, setTableModal] = useState(false);
	const [tableData, setTableData] = useState<any>([]);
	const [cachedTableData, setCachedTableData] = useState<any>(null);
	const [download_csv_percentage, set_download_csv_percentage] = useState(0);
	const [hideCsvButton, setHideCsvButton] = useState(false);

	const allowSalesAccess = useSelector((state:any) => state.navigation.allowSalesAccess)
	const isCanceled = useSelector((state: any) => state.sales.isCancel);
	const isSaved = useSelector((state: any) => state.sales.isSave);
	const globalCustomMarketplaces = useSelector((state: any) => state.customMarketplaces.customMarketplaces);

	const navigate = useNavigate();
	const token: any = Auth.get_token();
	const location = useLocation();

	const tippyElementStickyColumnsRefSales = useRef<any>(null);
	const [stickyColumnsListSales, setStickyColumnsListSales] = useState<any>([]);
	const [salesColumnsSizes, setSalesColumnsSizes] =  useState<any>([])

	const [getMarketsFromExtension, setGetMarketsFromExtension] = useState<any>(null);
	const [includesError, setIncludesError] = useState(false);

	const [connectedMarketplacesModal, setConnectedMarketplacesModal] = useState(false);

	const innerRef = useRef();
	const imageRef = useRef() ;
	const titleRef = useRef();
	const actionsRef = useRef();
	const totalSoldPriceRef = useRef();
	const dateSoldRef = useRef();
	const soldOnRef = useRef();
	const platformsRef = useRef();
	const showRef = useRef();
	const skuRef = useRef();
	const feesRef = useRef();
	const shippingCostBuyerRef = useRef();
	const shippingCostSellerRef = useRef();
	const cogsRef = useRef();
	const linkedRef = useRef();
	const quantitySoldRef = useRef();
	const quantityAvailableRef = useRef();
	const salesTaxRef = useRef();
	const shippingStatusRef = useRef();
	const profitRef = useRef();


	const baseColumns = [
		{ heading: 'Image', value: 'image', ref: imageRef },
		{ heading: 'Title', value: 'title', ref:titleRef },
		{ heading: 'Actions', value: 'actions', ref: actionsRef },
		{ heading: 'Total Sold Price', value: 'total_sold_price', ref: totalSoldPriceRef },
		{ heading: 'Date Sold', value: 'date_sold', ref: dateSoldRef },
		{ heading: 'Sold On', value: 'sold_on', ref: soldOnRef },
		{ heading: 'Platforms', value: 'platforms', ref: platformsRef },
		{ heading: 'Shop', value: 'shop_id', ref: showRef },
		{ heading: 'SKU', value: 'sku', ref: skuRef },
		{ heading: 'Fees', value: 'fees', ref: feesRef },
		{ heading: 'Shipping Cost Buyer', value: 'shipping_cost_buyer', ref: shippingCostBuyerRef },
		{ heading: 'Shipping Cost Seller', value: 'shipping_cost_seller', ref: shippingCostSellerRef },
		{ heading: 'COGS', value: 'cogs', ref: cogsRef },
		{ heading: 'Linked', value: 'linked', ref: linkedRef },
		{ heading: 'Quantity Sold', value: 'quantity_sold', ref: quantitySoldRef },
		{ heading: 'Quantity Available', value: 'quantity_available', ref:	quantityAvailableRef },
		{ heading: 'Sales Tax', value: 'sales_tax', ref: salesTaxRef },
		{ heading: 'Profit', value: 'profit', ref: profitRef },

	];

	const [columns, setColumns] = useState<any>([
		...baseColumns,
		...(hasPro && is_role_allowed_master ? [{ heading: 'Shipping Status', value: 'shipping_status', ref: shippingStatusRef }] : []),
	]);
	const showEditAddModal = useSelector((state:any) => state.navigation.editAddModalShow);
	const showModalQuestion = useSelector((state: any) => state.navigation.showModalQuestion);
	const [pageCount, setPageCount] = useState<number>(0);
	const [isLoading, setIsLoading] = useState(true);
	const [loader, setLoader] = useState(false);
	const [hasExtensionSupport, setHasExtensionSupport] = useState(false);
	const [hasExtensionInstall, setHasExtensionInstall] = useState(false);

	const dispatch = useDispatch();


	const showNotificationModal = useSelector((state: any) => state.navigation.showNotificationModal);
	const showSecureModal = useSelector((state: any) => state.navigation.showSecureModalWhenChangePage);
	const showListingViewModal = useSelector((state: any) => state.navigation.listingViewModalShow);
	const cancelToken = useSelector((state: any) => state.navigation.cancelToken);
	const [cancelFirstLoad, setCancelFisrtLoad] = useState(false);
	const [fetchingPrint, setFetchingPrint] = useState(false);

	const candidatIframeLink = useSelector((state: any) => state.navigation.candidatIframeLink);


	const [retryActive, setRetryActive] = useState(false);
	const [saveActive, setSaveActive] = useState(false);



	// Filter By Area. Start
	const assemble_filter_by_url_json : any = () => {

		const query_params = new URLSearchParams(window.location.search);
		const encoded_filter_by : any = query_params.get('filter_by');
		let assebled_filter_by_url_json = json_parse_safe_object(decodeURIComponent(encoded_filter_by));

		// Set default value if no date_sold_range presented
		const date_sold_range = _.get(assebled_filter_by_url_json, 'date_sold_range');
		if(_.isEmpty(date_sold_range)) _.set(assebled_filter_by_url_json, 'date_sold_range', 'last-30-days');

		return assebled_filter_by_url_json;

	};
	const assemble_filter_by_from_filter_by_url_json = (filter_by_url_json : any) => {

		// Init variable
		let assebled_filter_by : any = {};

		const date_sold_range = _.get(filter_by_url_json, 'date_sold_range');

		// Handle hardcoded date ranges
		if(date_sold_range === 'last-24-hours') _.set(assebled_filter_by, 'date_sold_range.start', moment().subtract(24, 'hours').format());
		if(date_sold_range === 'last-7-days') _.set(assebled_filter_by, 'date_sold_range.start', moment().subtract(7, 'days').format());
		if(date_sold_range === 'last-30-days') _.set(assebled_filter_by, 'date_sold_range.start', moment().subtract(30, 'days').format());
		if(date_sold_range === 'last-90-days') _.set(assebled_filter_by, 'date_sold_range.start', moment().subtract(90, 'days').format());
		if(date_sold_range === 'last-365-days') _.set(assebled_filter_by, 'date_sold_range.start', moment().subtract(365, 'days').format());

		// Handle custom date range
		if(typeof date_sold_range !== 'string') assebled_filter_by.date_sold_range = date_sold_range;


		// Handle shipping status
		const shipping_status = _.get(filter_by_url_json, 'shipping_status');
		if(shipping_status) _.set(assebled_filter_by, 'shipping_status', shipping_status);

		// Handle marketplaces
		const marketplaces = _.get(filter_by_url_json, 'marketplaces') || [];
		if(!_.isEmpty(marketplaces)) _.set(assebled_filter_by, 'marketplaces', marketplaces);

		// Handle not marketplaces
		const not_marketplaces = _.get(filter_by_url_json, 'not_marketplaces') || [];
		if(!_.isEmpty(not_marketplaces)) _.set(assebled_filter_by, 'not_marketplaces', not_marketplaces);

		// Handle order_ids
		const order_ids = _.get(filter_by_url_json, 'order_ids') || [];
		if(!_.isEmpty(order_ids)) _.set(assebled_filter_by, 'order_ids', order_ids);

		// Return result
		return assebled_filter_by;

	};
	const [filter_by_url_json, set_filter_by_url_json] = useState(assemble_filter_by_url_json());
	const filter_by = useMemo(() => {
		return assemble_filter_by_from_filter_by_url_json(filter_by_url_json);
	}, [filter_by_url_json]);

	// TODO separate filter_by_for_stats is just a temporary solution
	const [filter_by_for_stats, set_filter_by_for_stats] = useState({...filter_by});

	const [options, set_options] = useState({ include_listings_catalog_metadata: true });
	const [metadata, set_metadata] = useState({ offset: 0, limit: Number(localStorage.getItem('SalesPerPage')) || 10 });

	// Filter By Area. End

	// This function is called every time when filter_by_url_json changes and it sets url
	useEffect(() => {

		const launch = async () => {

			try {

				const filter_by_local = assemble_filter_by_from_filter_by_url_json(filter_by_url_json);

				// console.log('navigate', filter_by_url_json);

				// Redirect to pretty looking URL
				if(_.isEmpty(filter_by_url_json)) return navigate(`/sales`);

				// Redirct with applied filter_by
				const filter_by_url_json_encoded = encodeURIComponent(JSON.stringify(filter_by_url_json));
				navigate(`/sales?filter_by=${filter_by_url_json_encoded}`);

				// Activate loader
				setIsLoading(true);

				// Fetch data
				const orders_list_response : any = await OrdersService.list(filter_by_local, metadata, options, null, AuthService.get_token());
				const orders_list_response_orders = _.get(orders_list_response, 'data') || [];
				const orders_list_response_metadata_total = _.get(orders_list_response, 'metadata.total') || 0; // Set 0 to be default

				// Set pagination page count
				setPageCount(Math.ceil(orders_list_response_metadata_total) / metadata.limit);


				// Deactivate loader
				setIsLoading(false);
				setLoader(false);

				// Set new data to the table
				return setTableData(tableDataTransform(orders_list_response_orders));

			} catch (error) {
				console.log(error);
				toast.error(`Cannot fetch sales. Please, refresh the page.`, {position: 'bottom-right'});
			}

		};
		launch();

	}, [filter_by_url_json]);

	useEffect(() => {
		try{
			const searchParams = new URLSearchParams(location.search);
			const modalListingId = searchParams.get('modal-listing-id');
			if (modalListingId) {
				const location = window.location;
				const searchParams = new URLSearchParams(location.search)
				searchParams.set('modal', 'listing-view');
				searchParams.set('modal-listing-id', `${modalListingId}`);
				navigate(`?${searchParams.toString()}`);
				dispatch(setShowListingViewModal(true));
				dispatch(setListingViewId(modalListingId))
			}
		}
		catch(e){
			console.log(e, 'error')
		}
	}, []);

	// TODO change later
	const [dateRangeTableDateFilter, setDateRangeTableDateFilter] = useState(() => {

		let date_sold_range_filter = { start: '', end: '' };
		const date_sold_range_start = _.get(filter_by_url_json, 'date_sold_range.start');
		if(date_sold_range_start) _.set(date_sold_range_filter, 'start', date_sold_range_start);
		const date_sold_range_end = _.get(filter_by_url_json, 'date_sold_range.end');
		if(date_sold_range_end) _.set(date_sold_range_filter, 'end', date_sold_range_end);

		return date_sold_range_filter;

	});

	// TODO. Rebuild later. Start
	const transformedMarketplaces = (marketplace : string) => {
		if(marketplace === 'ebay') return 'eBay';
		else return _.capitalize(marketplace);
	};
	const transformedNotMarketplaces = (marketplace : string) => {
		if(marketplace === 'ebay') return 'Not on eBay';
		else return `Not on ${_.capitalize(marketplace)}`;
	};
	const [activePlatforms, setActivePlatforms] = useState(() => {
		const marketplaces = _.get(filter_by_url_json, 'marketplaces') || [];
		const not_marketplaces = _.get(filter_by_url_json, 'not_marketplaces') || [];
		return [
			...marketplaces.map((marketplace: string) => transformedMarketplaces(marketplace)),
			...not_marketplaces.map((marketplace: string) => transformedNotMarketplaces(marketplace))
		];
	});
	// TODO. Rebuild later. End

	const [lpPlatforms, setLpPlatforms] = useState<any>(platforms);

	const [isBulkEdit, setIsBulkEdit] = useState<boolean>(false);
	const [pagiInputValue, setPagiInputValue] = useState<string | number>('1');
	const dateRangeValueFromUrl: any = getValueForDateSoldRangeFromUrl();


	const [selectedOption, setSelectedOption] = useState(
	 {
		 value: typeof dateRangeValueFromUrl === 'string' || dateRangeValueFromUrl === null
		  ? '' : 'custom',
		 label: typeof dateRangeValueFromUrl === 'string' || dateRangeValueFromUrl === null
		  ? (  dateRangeValueFromUrl || (filter_by_url_json?.order_ids && 'All Time') || 'Last 30 Days' ): ('Custom')
	 }
	);

	const [customActive, setCustomActive] = useState(false);


	// This function is responsible for column filters TODO not debounced anymore
	const handleGetColumnSearchDebounced = (col: string, value: any) => {

		// console.log(col, value, 'handleGetColumnSearchDebounced');

		// Activates loader
		setLoader(true);

		// TODO strange naming. Setting page # to 1
		setPagiInputValue('1');

		let new__filter_by_url_json = { ...filter_by_url_json };

		if(col === 'shipping_status'){
			if(value) new__filter_by_url_json.shipping_status = value;
			else new__filter_by_url_json = _.omit(new__filter_by_url_json, ['shipping_status']);
		}

		if(col === 'platforms'){

			// Platforms
			const platforms = _.get(value, 'platforms') || [];
			if(!_.isEmpty(platforms)) new__filter_by_url_json.marketplaces = platforms;
			else new__filter_by_url_json = _.omit(new__filter_by_url_json, ['marketplaces']);

			// Not platforms
			let not_platforms = _.get(value, 'not_platforms') || [];
			if(!_.isEmpty(value)) new__filter_by_url_json.not_marketplaces = not_platforms;
			else new__filter_by_url_json = _.omit(new__filter_by_url_json, ['not_marketplaces']);

		}


		set_filter_by_url_json(new__filter_by_url_json);


	};


	// Populates page with the new data on date range dropdown change
	const handleDateRangeDropdownChange = async (selected: any) => {

		// Get selected value
		const selected_value = _.get(selected, 'value');
		// console.log(selected_value, 'selected_value');

		// Set loader & selected option
		setLoader(true);
		setSelectedOption(selected);

		// TODO for some reason handler of the custom date isn't included in this function figure out why.
		if (selected_value === 'custom'){
			setLoader(false);
			setIsLoading(false);
			return;
		}

		const new__filter_by_url_json = { ...filter_by_url_json, date_sold_range: selected_value };
		set_filter_by_url_json(new__filter_by_url_json);
		set_filter_by_for_stats({...assemble_filter_by_from_filter_by_url_json(new__filter_by_url_json)}); // TODO replace that later, workarond
	};


	// Date range dropdown options + styles
	const selectOptions = [
		{value: 'last-24-hours', label: 'Last 24 Hours'},
		{value: 'last-7-days', label: 'Last 7 Days'},
		{value: 'last-30-days', label: 'Last 30 Days'},
		{value: 'last-90-days', label: 'Last 90 Days'},
		{value: 'last-365-days', label: 'Last Year (365 days)'},
		{value: 'all-time', label: 'All Time'},
		{value: 'custom', label: 'Custom'},
	];
	const customStyles = {
		control: (provided: any) => ({
			...provided,
			backgroundColor: '#fff',
			color: '#1E3166',
			borderColor: '#D5D5D5',
			padding: !isSmallScreenLaptop ? 1 : 0.8,
			borderRadius: !isSmallScreenLaptop ? '4px' : '3px',
			cursor: 'pointer',
			minWidth: '88px',
			width: !isSmallScreenLaptop ? '200px' : '160px',
			minHeight: !isSmallScreenLaptop ? '39px' : '32px',
			height: !isSmallScreenLaptop ? '39px' : '32px',
			zIndex: 99,
		}),
		valueContainer: (provided: any) => ({
			...provided,
			height: !isSmallScreenLaptop ? '39px' : '32px',
			padding: !isSmallScreenLaptop ? '0 6px' : '0 5px',
		}),

		input: (provided: any) => ({
			...provided,
			margin: '0px',
		}),
		indicatorSeparator: (state:any) => ({
			display: 'none',
		}),
		indicatorsContainer: (provided: any) => ({
			...provided,
			height: !isSmallScreenLaptop ? '39px' : '32px',
		}),

		menu: (provided: any, state: any) => ({
			...provided,
			width: '100%',
			minWidth: !isSmallScreenLaptop ? '200px' : '160px',
			border: '1px solid #1E3166',
			borderRadius: !isSmallScreenLaptop ? '8px' : '6px',
			paddingTop: !isSmallScreenLaptop ? '10px' : '8px',
			zIndex: 99,
		}),
		option: (provided: any, state: any) => ({
			...provided,
			cursor: 'pointer',
			fontWeight: 600,
			fontSize: !isSmallScreenLaptop ? '16px' : '13px',
			zIndex: 99,
			padding:'6px 10px'
		}),
	};

	// TODO. This redirect should be moved to the higher level, to routes.
	useEffect(() => {
		if ((hasSimple || !((hasSubRole && allowSalesAccess) || !hasSubRole) )) {
			window.location.href = '/listings';
		}
	}, []);

	useEffect(() => {
		try{
			const savedName = localStorage.getItem("activeColumnNameSales");
			const firstThreeColumn = columns.slice(0, 3).map((column:any, index:any) => ({
				...column,
				activeColumn: false,
				stickyColumn: false, // Default value
				position: index + 1,
			}));
			if (savedName) {
				// Find the index of the active column
				const activeIndex = firstThreeColumn.findIndex(
					(item:any) => item.value?.toLowerCase() === savedName.toLowerCase()
				);
				const updatedColumns = firstThreeColumn.map((item:any, index:any) => ({
					...item,
					activeColumn: index === activeIndex,
					stickyColumn: index <= activeIndex, // Make all columns up to and including the active one sticky
				}));
				setStickyColumnsListSales(updatedColumns);
			} else {
				setStickyColumnsListSales(firstThreeColumn);
			}
		}catch(e){
			console.log(e, 'error')
		}
	}, [columns]);


	useEffect(() => {
		if (selectedOption.value === 'custom') {
			setCustomActive(true);
		} else {
			setDateRangeTableDateFilter({ start: '', end: '' });
			setCustomActive(false);
		}
	}, [selectedOption]);


	const handleChangeBulkEdit = () => {
		dispatch(setIsSaveSales(false));
		dispatch(setIsCancelSales(false));
		setRetryActive(false);
		setSaveActive(false);
		setIsBulkEdit(!isBulkEdit);
	};
	const handleChangeBulkEditCancel = () => {
		dispatch(setIsCancelSales(true));
		setIsBulkEdit(!isBulkEdit);
		setSaveActive(false);
	};

	// This function seems to be handling Connection Wizard
	useEffect(() => {
		const bodyElement = document.body;
		const handleAttributeChange = () => {
			const connectionWizardMarkets = bodyElement.getAttribute('lp_custom_chrome_extension_marketplaces_connected');
			try {
				if (connectionWizardMarkets) {
					// Decode HTML entities using Lodash
					setHasExtensionSupport(true);
					const decodedData = _.unescape(connectionWizardMarkets);
					const parsedArray = JSON.parse(decodedData);
					// Check if the new data is different from the current state
					if (JSON.stringify(parsedArray) !== JSON.stringify(getMarketsFromExtension)) {
						setGetMarketsFromExtension(parsedArray);
					}
				}
				else {
					setHasExtensionSupport(false);
				}
			} catch (e) {
				console.log('error', e);
			}
		};

		const observer = new MutationObserver(() => {
			handleAttributeChange();
		});

		observer.observe(bodyElement, {
			attributes: true,
			attributeFilter: ['lp_custom_chrome_extension_marketplaces_connected'],
		});

		// Initial attribute check
		handleAttributeChange();

		return () => {
			observer.disconnect();
		};
	}, [getMarketsFromExtension]);

	// This function seems to be handling Chrome Extension Version
	useEffect(() => {
		const bodyElement = document.body;
		const handleAttributeChange = () => {
			const extensionVersion = bodyElement.getAttribute('lp_custom_chrome_extension_version');
			try {
				if (extensionVersion) {
					setHasExtensionInstall(true)
				}
				else {
					setHasExtensionInstall(false)
				}
			} catch (e) {
				console.log('error', e);
			}
		};

		const observer = new MutationObserver(() => {
			handleAttributeChange();
		});

		observer.observe(bodyElement, {
			attributes: true,
			attributeFilter: ['lp_custom_chrome_extension_version'],
		});

		// Initial attribute check
		handleAttributeChange();

		return () => {
			observer.disconnect();
		};
	}, []);



	const checkForError = ()=>{
		const hasConnectionError = getMarketsFromExtension?.some((item: any) => item.hasOwnProperty('connection_status_extension_error'));
		if (hasConnectionError) {
			setIncludesError(true)
		}else{
			setIncludesError(false)
		}
	}
	useEffect(() => {
		checkForError();
	}, [getMarketsFromExtension]);



	// TODO extension
	useEffect(() => {
		handleGetColumns();

		const questions = document.querySelectorAll(
		 '.lp_custom_help_bubble_button_container'
		);
		Array.from(questions)?.forEach((question) => {
			if (question instanceof HTMLElement) {
				question.style.display = 'none';
			}
		});
		localStorage.removeItem('currentPageNumber');
	}, []);

	// TODO figure out what is it for
	useEffect(() => {
		if ((tableData?.length && !cachedTableData) || isSaved) {
			setCachedTableData(tableData);
		}
	}, [tableData, cachedTableData, isSaved]);

	// TODO figure out what is it for
	useEffect(() => {
		if (isCanceled && cachedTableData) {
			setTableData(cachedTableData);
			setCachedTableData(null);
		}
	}, [isCanceled]);

	type FormatType = "urlFormat" | "labelFormat";

	function formattedString(input: string, format: FormatType): string {
		const mappings: { [key: string]: string } = {
			"Last 24 Hours": "last-24-hours",
			"Last 7 Days": "last-7-days",
			"Last 30 Days": "last-30-days",
			"Last 90 Days": "last-90-days",
			"Last Year (365 days)": "last-365-days",
			"last-24-hours": "Last 24 Hours",
			"last-7-days": "Last 7 Days",
			"last-30-days": "Last 30 Days",
			"last-90-days": "Last 90 Days",
			"last-365-days": "Last Year (365 days)",
			'All Time' : 'all-time',
			'all-time' : 'All Time'
		};
		if (format === "urlFormat") {
			return mappings[input] || "";
		} else if (format === "labelFormat") {
			return mappings[input.toLowerCase()] || "";
		} else {
			throw new Error("Invalid format type.");
		}
	}


	// This function seems to be handling pick list
	const handlePrint = useCallback(async () => {
		const current_payload = {

			metadata: {offset: 0, limit: 50},
			filter_by: filter_by,
			options: {
				include_listings_catalog_metadata: true,
			},
		};
		const divider_number = 30;
		const max_concurrency = 2;

		try {
			setFetchingPrint(true);
			const data = await getAllDataByPayloadByPrint(
			 current_payload,
			 divider_number,
			 max_concurrency,
			 cancelToken
			);
			if (data) {
				setFetchingPrint(false);
				dispatch(setDataForPrint(skuSortListings(tableDataTransform(data))));

				if (!_.includes(data, 'canceled')) {
					navigate('/print');
				}
			}
		} catch (error) {
			console.error(error);
		}
	}, [filter_by, cancelToken]);


	useEffect(() => {
		if (cancelFirstLoad) {
			handlePrint();
		}
	}, [cancelToken, cancelFirstLoad, filter_by]);

	useEffect(() => {
		dispatch(setRoles(roles));
	}, [dispatch]);

	const handleStart = () => {
		setCancelFisrtLoad(true);
		dispatch(setCancelToken(false));
	};

	// TODO figure out what is it for
	useEffect(() => {
		const checkIsMobile = () => {
			const mobileWidthThreshold = 768;
			const screenWidth =
			 window.innerWidth ||
			 document.documentElement.clientWidth ||
			 document.body.clientWidth;
			dispatch(setIsMobile(screenWidth < mobileWidthThreshold));
		};
		checkIsMobile();
		window.addEventListener('resize', checkIsMobile);
		return () => {
			window.removeEventListener('resize', checkIsMobile);
		};
	}, []);

	const handleGetColumns = () => {
		const tempColumnsString = localStorage.getItem('salesCols');
		const tempColumns = tempColumnsString
		 ? JSON.parse(tempColumnsString)
		 : null;

		const filteredData = tempColumns?.filter((item :any) => item?.heading !== undefined);

		setColumns(
		 filteredData?.length === 0 || filteredData === undefined || null ? columns : filteredData
		);
	};


	// This function handles pagination #1 TODO strange name, not descriptive
	const handleChangeByInput = async (num: number) => {
		let currentPage: any = num - 1;

		localStorage.setItem('currentPageNumber', currentPage);

		// TODO figure out what is this if statement for
		if(pagiInputValue !== '0' && pagiInputValue !== '') {
			set_metadata({ ...metadata, offset: currentPage * metadata.limit });
			set_filter_by_url_json({...filter_by_url_json});
		}
	};



	const handleChangeModal = () => {
		setTableModal(!tableModal);
	};

	const handleChangeCheckbox = (
	 e: React.ChangeEvent<HTMLInputElement>
	): void => {
		const {name, checked} = e.target;

		if (name === 'allSelect') {
			const tempDatas = tableData.map((tbData: any) => ({
				...tbData,
				isChecked: checked,
			}));

			setTableData(tempDatas);
		} else {
			const tempDatas = tableData.map((tbData: any) =>
			 tbData.id === +name ? {...tbData, isChecked: checked} : tbData
			);

			setTableData(tempDatas);
		}
	};

	const handleRowSelected = useCallback(
	 (e: any, id: string): void => {
		 let tempDatas = tableData.map((tbData: any) =>
		  tbData.id === id ? {...tbData, isChecked: !tbData.isChecked} : tbData
		 );

		 setTableData(tempDatas);
	 },
	 [tableData, setTableData]
	);


	// This function handles click on custom date range apply button
	const handleClickFilterApply = async () => {

		setLoader(true);

		// TODO temporary workaround
		let date_sold_range = { ...dateRangeTableDateFilter };
		const date_sold_range_start = _.get(date_sold_range, 'start');
		if(!date_sold_range_start) date_sold_range = _.omit(date_sold_range, ['start']);
		const date_sold_range_end = _.get(date_sold_range, 'end');
		if(!date_sold_range_end) date_sold_range = _.omit(date_sold_range, ['end']);

		const new__filter_by_url_json = { ...filter_by_url_json, date_sold_range };
		if(_.isEmpty(date_sold_range)) _.set(new__filter_by_url_json, 'date_sold_range', 'last-30-days');
		set_filter_by_url_json(new__filter_by_url_json);
		set_filter_by_for_stats({...assemble_filter_by_from_filter_by_url_json(new__filter_by_url_json)}); // TODO replace that later, workarond


	};

	const handleEditColumns = async (cols: string[]) => {
		const myNewCols = cols.map((item) => {
			return {
				heading:
				 item === 'cogs'
				  ? 'COGS'
				  : item === 'sku'
				   ? 'SKU'
				   : formatString(item),

				value: item,
				ref: innerRef,
			};
		});

		localStorage.setItem('salesCols', JSON.stringify(myNewCols));
		handleGetColumns();
		setTableModal(!tableModal);
	};

	useEffect(() => {
		const storageOldColumns: any = localStorage.getItem('salesCols');
		const storageOldColumnsSize: any = localStorage.getItem('salesSize');

		try {
			const parsedStorageColumns = JSON.parse(storageOldColumns);
			const parsedStorageColumnsSize = JSON.parse(storageOldColumnsSize);

			const updatedStorageColumns = parsedStorageColumns?.map((item: any) => {
				if (item.value === 'price') {
					return {
						heading: 'Total Sold Price',
						value: 'total_sold_price',
						ref: item.ref,
					};
				} else {
					return item;
				}
			});

			if (updatedStorageColumns) {
				localStorage.setItem(
				 'salesCols',
				 JSON.stringify(updatedStorageColumns)
				);
			}

			const updateStorageColumnsSize = parsedStorageColumnsSize?.map(
			 (item: any) => {
				 if (item.name === 'price') {
					 return {
						 name: 'total_sold_price',
						 size: '150px',
					 };
				 } else {
					 return item;
				 }
			 }
			);

			if (updateStorageColumnsSize) {
				localStorage.setItem(
				 'salesSize',
				 JSON.stringify(updateStorageColumnsSize)
				);
			}
		} catch (error) {
			console.error(error);
		}
	}, []);


	// TODO figure out what is it for.
	useEffect(() => {
		try {
			const updatedPlatforms = platforms.map((platform) => {
				if (
				 filter_by_url_json?.marketplaces?.includes(
				  platform.platform.title.toLowerCase()
				 )
				) {
					return {
						...platform,
						platform: {
							...platform.platform,
							isChecked: true
						},
					};
				} else if (
					filter_by_url_json?.not_marketplaces?.includes(
				  platform.platform.title.toLowerCase()
				 )
				) {
					return {
						...platform,
						notOnPlatform: {
							title: `Not on ${platform.platform.title}`,
							isChecked: true,
						},
					};
				} else {
					return platform;
				}
			});

			setLpPlatforms(updatedPlatforms);
		} catch (e: any) {
			console.log(e, 'e');
		}
	}, []);


	// This function is responsible for items per page dropdown
	const changeLimit = async (new_limit: number) => {

		setLoader(true);

		setPagiInputValue('1');
		localStorage.setItem('currentPageNumber', '0');
		set_metadata({ offset: 0, limit: new_limit });
		set_filter_by_url_json({ ...filter_by_url_json });

	};


	// This function is handling bulk edit
	const handleSaveBulkEdit = async () => {
		// console.log('handleSaveBulkEdit is working')
		let updatedTableData:any = [...tableData];

		// TODO find out what is that for
		dispatch(setIsSaveSales(true));

		// If no changes were made and save button clicked by user - return warning message.
		if(!saveActive){
			return toast.warning(`Oops! It looks like no changes were made to your listing. Please edit something for the 'Save' button to become active.`, {
				position: 'bottom-right', // You can customize the notification position
			});
		}

		// TODO find out what is that for
		dispatch(setIsSave(true));

		// TODO get rid of this function, it's not necessary at all.
		const createPayload = (data: any, prefix: string) => {

			const payload: any = {};

			for (let key in data) {
				let value = data[key];

				if(key.startsWith(prefix) && !key.startsWith('new__')) {
					const newKey = key.replace(new RegExp(`^${prefix}`), '');
					let formattedKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);


					if (prefix === 'new') {
						if (formattedKey === 'shippingStatus') {
							formattedKey = 'shipping_status';
							value = convertToSlug(value);
						} else if (formattedKey === 'quantityAvailable') {
							formattedKey = 'quantity_available';
							value = parseFloat(value);
						}
					}

					if (prefix === 'newMultiItem') {
						if (formattedKey.includes('sku')) {
							formattedKey = 'sku';
						} else if (formattedKey.includes('cogs')) {
							formattedKey = 'cogs';
							value = parseFloat(value);
						} else if (formattedKey.includes('quantityAvailable')) {
							formattedKey = 'quantity_available';
							value = parseFloat(value);
						} else if (formattedKey.includes('quantitySold')) {
							formattedKey = 'quantity_sold';
							value = parseFloat(value);
						}
					}

					payload[formattedKey] = value;
				}

				// This is handling changed values more dynamically. First step in refactoring.
				if(key.startsWith('new__')){

					// TODO exceptions below. Fix later
					if(key === 'new__total_price') payload.total_price = value;
					if(key === 'new__fees') payload.fees = value;
					if(key === 'new__shipping_cost_seller') payload.shipping_cost_seller = value;
					if(key === 'new__shipping_cost_buyer') payload.shipping_cost_buyer = value;
					if(key === 'new__sales_tax') payload.sales_tax = value;

					// TODO. Temporary exceptions (single-item)
					if(key === 'new__cogs') payload.cogs = value;
					if(key === 'new__quantity_sold') payload.quantity_sold = value;

				}

			}

			return payload;
		};


		// This section is generating orders and order listings update requests(A)
		const requests = updatedTableData
		.filter((order:any) => order.changed || (order.listings && order.listings.some((listing:any) => listing.multiItem)))
		.flatMap((order:any) => {

			const requestsArray = [];
			if(order.changed) {
				// This is updating orders instance
				const payload_1 = createPayload(order, 'new');
				requestsArray.push(OrdersService.update(AuthService.get_token(), { id: order.id }, payload_1));
			}
			if(order.listings){
				const listingRequests = order.listings
				.filter((listing:any) => listing.multiItem)
				.map((listing:any) => {
					// This is updating order listing instance
					const payload_2 = createPayload(listing, 'newMultiItem');
					return OrdersService.listings_update(AuthService.get_token(), { id: listing.id }, payload_2);
				});
				requestsArray.push(...listingRequests);

			}

			return requestsArray;

		});

		// This section is processing requests(A)
		try {

			const responses = await Promise.all(requests);
			updatedTableData = tableData.map((order:any) => {

				// This un-checks changed property for orders and order listings
				if(order.changed || (order.listings && order.listings.some((listing:any) => listing.multiItem))) {

					// Get rid of the properties which are starting with new__ prefix
					// order = _.omit(order, ['new__total_price']);

					// Get rid of the changed property
					order = _.omit(order, ['changed']);


					if (order.listings) {
						order.listings = order.listings.map((listing:any) => {
							if(listing.multiItem){
								listing.multiItem = false;
							}
							return listing;
						});
					}
				}



				// This iterates through responses and finds the one matching order. Once found, it populates updatedTableData
				// array with response data. (B)
				const response = responses.find(response => response.data.id === order.id);
				if(response){

					setRetryActive(false);
					setSaveActive(true);

					const responseData = response.data;
					return { ...order, ...responseData };

				}

				// This one does the same as (B), but instead of orders it loops through order listings. (setRetryActive and setSaveActive are missing. TODO figure out why)
				if(order.listings) {
					order.listings = order.listings.map((listing:any) => {
						const listingResponse = responses.find(response => response.data.id === listing.id);
						if (listingResponse) {
							return { ...listingResponse.data };
						}
						return listing;
					});
				}

				return order;

			});


			// This function updates table immediately right after data is saved
			setTableData(tableDataTransform(updatedTableData));

			handleChangeBulkEdit();

			// This function just triggers re-rendering of stats component when bulk edit changes.
			set_filter_by_for_stats({...filter_by_for_stats});


		} catch (error){

			toast.error(`Oops! We encountered an error while attempting to save your changes. Please click the 'Retry' button to try again.`, {
				position: 'bottom-right',
			});
			setRetryActive(true);
			setSaveActive(true);

		}

	};

	const handleOpenLinkInNewTab = () => {
		if (candidatIframeLink) {
			window.open(candidatIframeLink, '_blank');
			dispatch(setResetCandidatLink());
			dispatch(setResetPureCandidantLink());
		}
	};

	const handleReplaceCurrent = () => {
		if (candidatIframeLink) {
			dispatch(setReplaceCandidat());
			dispatch(setResetCandidatLink());
			dispatch(setResetPureCandidantLink());
		}
	};

	const handleBulkDelete = async () => {
		const requests: Promise<any>[] = [];

		const itemsToDelete = tableData.filter((item:any) => item.isChecked && item.status === 'active');
		const idsToDelete = new Set(itemsToDelete.map((item:any) => item.id));

		itemsToDelete.forEach((item:any) => {
			const requestData = {
				filter_by: { id: item.id },
				payload: { status: 'trash' },
			};
			const request = instanceAxios.put('/orders/update', requestData);
			requests.push(request);
		});

		const filteredTableData = tableData.filter((item:any) => !idsToDelete.has(item.id));

		try {
			await Promise.all(requests);
			setTableData(filteredTableData);
		} catch (error) {
			console.error(error);
		}
	};

	const debounceSearchItem = useDebounce(pagiInputValue, 1000);

	useEffect(() => {
		if (debounceSearchItem !== '1') {
			handleChangeByInput(+pagiInputValue);
		}
	}, [debounceSearchItem]);

	// TODO rebuild
	function getValueForDateSoldRangeFromUrl() {
		const url = new URL(window.location.href);
		const filterByParam = url.searchParams.get('filter_by');

		if (filterByParam) {
			try {
				const filterByObj = JSON.parse(decodeURIComponent(filterByParam));
				const date_sold_range = _.get(filterByObj, 'date_sold_range')
				if (typeof date_sold_range === 'string') {
					return formattedString(date_sold_range, 'labelFormat')
				} else if (!date_sold_range?.start && !date_sold_range?.end) {
					return null
				}
				else {
					return {
						start: date_sold_range?.start,
						end: date_sold_range?.end
					}
				}
			} catch (error) {
				console.error('Error parsing filter_by parameter:', error);
				return null;
			}
		} else {
			return null;
		}
	}

	const handleShowConnectedMarketsModal = () => {
		setConnectedMarketplacesModal(true);
	};

	useEffect(() => {
		function handleScroll() {
			const scrolled = window.innerHeight + window.scrollY;
			const docHeight = document.body.offsetHeight;

			if (docHeight > window.innerHeight + 50) {
				if (scrolled >= docHeight - 10) {
					setHideCsvButton(true);
				} else {
					setHideCsvButton(false);
				}
			} else {
				setHideCsvButton(false);
			}
		}

		window.addEventListener('scroll', handleScroll);
		handleScroll();

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);



	return (
	 <>
		 <Helmet>
			 <title>
				 Sales & Analytics | List Perfectly
			 </title>
		 </Helmet>
		 <div className={styles.salesPage}>
			 {tableModal && (
			  <TableEditColumn
			   handleChangeModal={handleChangeModal}
			   columns={columns}
			   handleEditColumns={handleEditColumns}
			   hasPro={hasPro}
			   hasBetaRole={hasBetaRole}
			   is_role_allowed_master={is_role_allowed_master}
			  />
			 )}
			 {showSecureModal && <PaginateMessageModal handleChangeBulkEditCancel={handleChangeBulkEditCancel}
													   handleSaveBulkEdit={handleSaveBulkEdit}/>}
			 {showListingViewModal && <ListingViewModal/>}
			 {showEditAddModal && <EditAndAddListingModal/>}
			 {showModalQuestion && (
			  <div className="questionModal">
				  <p>
					  Would you like to open in a new tab or replace the current one ?
				  </p>
				  <div
				   style={{display: 'flex', justifyContent: 'center', gap: '10px'}}
				  >
					  <Button handleClick={() => {
						  handleOpenLinkInNewTab();
						  dispatch(setRemoveQuestionModal());
					  }}
							  modifier={ModifierType.Transparent}
					  >New tab</Button>
					  <Button handleClick={() => {
					    handleReplaceCurrent();
					    dispatch(setRemoveQuestionModal());
					  }} modifier={ModifierType.Transparent}
					  > Replace current</Button>
				  </div>
			  </div>
			 )}
			 {showNotificationModal && <NotificationsModal/>}
			 <Iframe/>
			 <div className='hiddenElementForTippyStickyColumnsSales' ref={tippyElementStickyColumnsRefSales}></div>
			 <div
			  style={{display: 'block'}}
			  className="container"
			 >
					 <section>
						 <div
						  style={{
							  display: 'flex',
							  justifyContent: 'space-between',
							  alignItems: 'center',
							  padding: '10px 0 0 0',
						  }}
						 >
							 {connectedMarketplacesModal && (
							  <ConnectedMarketplacesModal
							   includesError={includesError}
							   getMarketsFromExtension={getMarketsFromExtension}
							   setConnectedMarketplacesModal={setConnectedMarketplacesModal}
							  />
							 )}
							 <div className={styles.salesPageLabel}>
								 <div
								  className={styles.title}>Sales
								 </div>
								 {!customActive ? (<div>{selectedOption.label}</div>) : ''}
								 {customActive ? (
								  <div>{moment(filter_by_url_json.date_sold_range.start).format('MM/DD/YYYY')} - {moment(filter_by_url_json.date_sold_range.end).format('MM/DD/YYYY')}</div>) : ''}

							 </div>
						 </div>


						 <Stats filter_by={filter_by_for_stats} selectedOption={selectedOption}/>
						 {download_csv_percentage ? <CsvDownloadProgressBar progress={download_csv_percentage} label={'sales'}/> : null}

						 <div className={styles.toolbar} id="salesElement">
							 <div className={styles.toolbar__left}>
								 <div className={styles.dateInput__box}>
									 <Select
									  value={selectedOption}
									  onChange={handleDateRangeDropdownChange}
									  options={selectOptions}
									  styles={customStyles}
									  placeholder=""
									  isSearchable={false}
									 />
								 </div>
								 {customActive && (
								  <div className={styles.reactDatePickerBlock}>
									  <ReactDatePicker
									   isLabel={false}
									   dateRangeTableDateFilter={dateRangeTableDateFilter}
									   setDateRangeTableDateFilter={setDateRangeTableDateFilter}
									   filter_by_url_json={filter_by_url_json}
									   set_filter_by_url_json={set_filter_by_url_json}
									  />
									  <Button handleClick={handleClickFilterApply} modifier={ModifierType.Primary} >
										  Apply
									  </Button>
								  </div>
								 )}
							 </div>
							 <div className={styles.toolbar__right}>
								 <div className={styles.perPageSelectBlock}>
									 <span>Per Page</span>
									 <PerPageSelect
									  handleGetDataPerPage={changeLimit}
									  type="Sales"
									 />
								 </div>
								 {
									 <TablePagination
									  isBulkEdit={isBulkEdit}
									  pageCount={pageCount}
									  pagiInputValue={pagiInputValue}
									  setPagiInputValue={setPagiInputValue}
									 />
								 }

								 {(hasExtensionInstall && hasExtensionSupport) && <Button modifier={ModifierType.Transparent}>
								 <div
								  className={`${styles.marketplacesBtn} lp_custom_marketplaces_connected_button`}
								  onClick={() => handleShowConnectedMarketsModal()}
								 >
									 <MarketIcon/>
									 <span>Connections</span>
									 <div
									  className={`${styles.marketplacesDot} ${(includesError || !getMarketsFromExtension?.length) ? styles.red : styles.green} lp_custom_marketplaces_connected_button_status`}></div>
								 </div>
							 </Button>}
								 {(hasExtensionInstall && !hasExtensionSupport) &&
								  <div className={"disabledMarketsOnSales"}>
									  <Tippy
									   interactive={true}
									   zIndex={5}
									   arrow={false}
									   trigger="focus click"
									   content={
										   <p>
											   Activate or <a target="_blank"
															  href={'https://help.listperfectly.com/en/articles/8913965'}>install</a> the
											   List Perfectly extension
										   </p>
									   }
									  >
										  <Button modifier={ModifierType.Transparent}>
											  <div
											   className={`${styles.marketplacesBtn} ${styles.marketplacesBtnDisabled}`}>
												  <MarketIcon/>
												  <span>Connections</span>
												  <div
												   className={`${styles.marketplacesDot} ${includesError ? styles.red : styles.green} lp_custom_marketplaces_connected_button_status`}></div>
											  </div>
										  </Button>
									  </Tippy>
								  </div>}
								 {
								  (!hasExtensionInstall && !hasExtensionSupport) &&
								  <div className={"disabledMarketsOnSales"}>
									  <Tippy
									   interactive={true}
									   zIndex={5}
									   arrow={false}
									   trigger="focus click"
									   content={
										   <p>
											   Activate or <a target="_blank"
															  href={'https://help.listperfectly.com/en/articles/8913965'}>install</a> the
											   List Perfectly extension
										   </p>
									   }
									  >
										  <div
										   className={`${styles.marketplacesBtn} ${styles.marketplacesBtnDisabled}`}>
											 <Button modifier={ModifierType.Transparent}>
												 <MarketIcon/>
												 <span>Connections</span>
												 <div
												  className={`${styles.marketplacesDot} ${includesError ? styles.red : styles.green} lp_custom_marketplaces_connected_button_status`}></div>
											 </Button>
										  </div>
									  </Tippy>

								  </div>
								 }

								 {!hideCsvButton ? <DownloadCSV
								   filter_by={filter_by}
								   options={options}
								   metadata={metadata}
								   sort_by={null}
								   token={token}
								   globalCustomMarketplaces={globalCustomMarketplaces}
								   download_csv_percentage={download_csv_percentage}
								   set_download_csv_percentage={set_download_csv_percentage}/> : null}



								 <Button style={{
									 color:'#1E3166',
									 border:'1px solid #1E3166'
								 }} handleClick={handleChangeModal} modifier={ModifierType.Transparent}>
									 <CustomizeIcon className="settingsIconCustomize"/> Customize
								 </Button>
								 <div className={'picklistWrapper'}>
									 {hasPro && is_role_allowed_master ?
									  <Button handleClick={handleStart} modifier={ModifierType.Primary}>
										  <PrintIcon/>
										  <span>Pick List</span>
									  </Button>
									  : <Tippy
									   interactive={true}
									   zIndex={5}
									   arrow={false}
									   trigger="focus click"
									   content={
										   <p>
											   Generate printable pick list from recent orders with the
											   <a
												href={'https://help.listperfectly.com/en/articles/9512203-access-more-features-with-pro-plus'}
												target="_blank"> Pro Plus </a>plan.
										   </p>
									   }
									  >
										 <div>
											 <Button modifier={ModifierType.Primary} disabled={true}>
												 <PrintIcon/>
												 <span>Pick List</span>
											 </Button>
										 </div>
									  </Tippy>}
								 </div>
								 {!isBulkEdit ? (
								  <div className={styles.bulkBtnWrapper}>
									  <Button handleClick={handleChangeBulkEdit} modifier={ModifierType.Secondary}>Bulk
										  Edit</Button>
								  </div>
								 ) : (
								  <div className={styles.saveAndCancel}>
									  <Button
									   handleClick={handleSaveBulkEdit}
									   modifier={ModifierType.Primary}
									   disabled={!saveActive}
									  >{retryActive ? 'Retry' : 'Save'}</Button>
									  <Button handleClick={handleChangeBulkEditCancel} modifier={ModifierType.Error}>Cancel
										  Edits</Button>
								  </div>
								 )}
							 </div>
						 </div>
						 {(filter_by?.order_ids || filter_by?.listingTitle) &&
						  <ResultsFor filterBy={filter_by_url_json}
									  setFilterBy={set_filter_by_url_json}
									  listingTitle={filter_by_url_json?.listingTitle || ""}/>}
						 {isLoading ? (
						  <Loader/>
						 ) : (
						  <Table
						   activePlatforms={activePlatforms}
						   setActivePlatforms={setActivePlatforms}
						   lpPlatforms={lpPlatforms}
						   setLpPlatforms={setLpPlatforms}
						   isLoading={isLoading}
						   handleGetColumnSearchDebounced={handleGetColumnSearchDebounced}
						   data={tableData}
						   columns={columns}
						   handleChangeCheckbox={handleChangeCheckbox}
						   handleRowSelected={handleRowSelected}
						   isBulkEdit={isBulkEdit}
						   tableData={tableData}
						   setTableData={setTableData}
						   filterBy={filter_by}
						   metadata={metadata}
						   loader={loader}
						   handleBulkDelete={handleBulkDelete}
						   retryActive={retryActive}
						   setRetryActive={setRetryActive}
						   saveActive={saveActive}
						   setSaveActive={setSaveActive}
						   hasBusiness={hasBusiness}
						   hasPro={hasPro}
						   is_role_allowed_master={is_role_allowed_master}
						   tippyElementStickyColumnsRefSales={tippyElementStickyColumnsRefSales}
						   stickyColumnsListSales={stickyColumnsListSales}
						   salesColumnsSizes={salesColumnsSizes}
						   setSalesColumnsSizes={setSalesColumnsSizes}
						   setStickyColumnsListSales={setStickyColumnsListSales}
						  />
						 )}
						 <div
						  className={`${styles.downPaginateBlock} ${
						   isLoading && styles.downPaginateBlockLoading
						  }`}
						 >

						 </div>
					 </section>
				 <div className="lp_custom_help_bubble_button_container"></div>
				 {fetchingPrint && <PrintLoader/>}
			 </div>
		 </div>
	 </>
	);
};

export default SalesPage;
