import AccordionHeaderComponent from "./AccordionHeaderComponent";
import {useEffect, useState} from "react";
import ProDescriptionsComponent from "../ProDescriptionsComponent";
import {KeywordsComponent} from "../KeywordsComponent";
import {ShippingAndVariationsComponent} from "../ShippingAndVariationsComponent";
import {InventoryAndSalesComponent} from "../InventoryAndSalesComponent";
import {CustomizationsComponent} from "../CustomizationsComponent";
import styles from './DropdownComponent.module.scss';
import Button, {ModifierType} from "../../../UI/Button";

const DropdownComponent = ({is_role_allowed,hasPro,  hasExtensionInstall, savingProgress,  isExtensionSupported, afterCreate, setDisableSaveButtons,enabledButtons, salesListings, setSalesListings, dataFromExtension, loaderForCustomMarkets,skuKeys, config,activeItemSKUValue, SKUprefix, SKUformat, skuGeneratorUniqueIdFormat, SKUInputValue, handleSKUitemClick, activeItemSKU, handleKeyPress, handleInputChangeSKU, setSKUloader, SKUloader, requestId, customMarketplacesTableRow,setCustomMarketplacesTableRow, addMoreInputs, setAddMoreInputs, moreMeasurementsPart1, setMoreMeasurementsPart1, moreMeasurementsPart2, setMoreMeasurementsPart2, optionsAssignee, selectedOptionAssignee, setSelectedOptionAssignee, assigneeId, hasSimple, hasBusiness, bulletItems, setBulletItems, measureItems, setMeasureItems, measureBothItems, setMeasureBothItems, variations, setVariations, subAccounts, setSubAccounts, setOptionsAssignee, editId, setFormInfo, handleTagifiesChange, handleStandartInputChange, editIndex, formInfo, unescapedRowMetadata, listInfo, itemData, setItemData, enableToShowSkuGenerator, hasExpandedDescription }:any) => {
    const [activeIndices, setActiveIndices] = useState<number[]>([]);

    useEffect(() => {
        if (hasSimple || hasBusiness) {
            setActiveIndices([2]);
        } else if (hasExpandedDescription) {
            setActiveIndices([1, 2, 3]);
        } else {
            setActiveIndices([2, 3]);
        }
    }, [hasSimple, hasBusiness, hasExpandedDescription]);

    const openAllDropdown = ()=>{
        if(hasSimple || hasBusiness){
            setActiveIndices([2, 4])
        }else{
            setActiveIndices([1,2,3,4,5])
        }
    }

    const closeAllDropdown = () =>{
        setActiveIndices([])
    }
    const maxIndices = hasBusiness || hasSimple ? 2 : 5;

    return(
        <div>
            <div className={styles.openCloseAllBtn}>
                <Button modifier={ModifierType.Secondary} handleClick={(activeIndices.length === maxIndices) ? closeAllDropdown : openAllDropdown}>
                    {(activeIndices.length === maxIndices) ? 'Close All' : 'Open All'}
                </Button>
            </div>
            {
                (!hasSimple && !hasBusiness) && <>
                    <AccordionHeaderComponent activeIndices={activeIndices} setActiveIndices={setActiveIndices} accIndex={1} accTitle="Pro Description (Optional)" />
                    {activeIndices.includes(1) && <ProDescriptionsComponent itemData={itemData} editIndex={editIndex} setItemData={setItemData} setFormInfo={setFormInfo} addMoreInputs={addMoreInputs} setAddMoreInputs={setAddMoreInputs} moreMeasurementsPart1={moreMeasurementsPart1} setMoreMeasurementsPart1={setMoreMeasurementsPart1} moreMeasurementsPart2={moreMeasurementsPart2} setMoreMeasurementsPart2={setMoreMeasurementsPart2} handleStandartInputChange={handleStandartInputChange} formInfo={formInfo} unescapedRowMetadata={unescapedRowMetadata}/>}</>
            }
            <AccordionHeaderComponent activeIndices={activeIndices} setActiveIndices={setActiveIndices} accIndex={2} accTitle="Keywords, Pricing, Quantity, SKU, UPC, COGS" />
            {activeIndices.includes(2) && <KeywordsComponent is_role_allowed={is_role_allowed} hasPro={hasPro} savingProgress={savingProgress} enabledButtons={enabledButtons} itemData={itemData} editIndex={editIndex} skuKeys={skuKeys} config={config} SKUInputValue={SKUInputValue} activeItemSKUValue={activeItemSKUValue} editId={editId} SKUprefix={SKUprefix} SKUformat={SKUformat} skuGeneratorUniqueIdFormat={skuGeneratorUniqueIdFormat} setFormInfo={setFormInfo} setItemData={setItemData} handleSKUitemClick={handleSKUitemClick} activeItemSKU={activeItemSKU} handleKeyPress={handleKeyPress} handleInputChangeSKU={handleInputChangeSKU} setSKUloader={setSKUloader} SKUloader={SKUloader} hasSimple={hasSimple} formInfo={formInfo} unescapedRowMetadata={unescapedRowMetadata} handleTagifiesChange={handleTagifiesChange} handleStandartInputChange={handleStandartInputChange} enableToShowSkuGenerator={enableToShowSkuGenerator}/>}
            {
                (!hasSimple && !hasBusiness) && <>
                    <AccordionHeaderComponent activeIndices={activeIndices} setActiveIndices={setActiveIndices} accIndex={3} accTitle="Shipping and Variations" />
                    {activeIndices.includes(3) && <ShippingAndVariationsComponent variations={variations} setVariations={setVariations} handleStandartInputChange={handleStandartInputChange} itemData={itemData} setItemData={setItemData} editIndex={editIndex} formInfo={formInfo} unescapedRowMetadata={unescapedRowMetadata} setDisableSaveButtons={setDisableSaveButtons} />}</>
            }
            <AccordionHeaderComponent activeIndices={activeIndices} setActiveIndices={setActiveIndices} accIndex={4} accTitle="Inventory and Sales" />
            {activeIndices.includes(4) && <InventoryAndSalesComponent afterCreate={afterCreate} hasExtensionInstall={hasExtensionInstall} isExtensionSupported={isExtensionSupported} salesListings={salesListings} setSalesListings={setSalesListings} setDisableSaveButtons={setDisableSaveButtons} hasSimple={hasSimple} setFormInfo={setFormInfo} editId={editId} setCustomMarketplacesTableRow={setCustomMarketplacesTableRow} customMarketplacesTableRow={customMarketplacesTableRow} requestId={requestId} loaderForCustomMarkets={loaderForCustomMarkets} handleStandartInputChange={handleStandartInputChange} editIndex={editIndex} setItemData={setItemData} itemData={itemData} listInfo={listInfo} formInfo={formInfo} unescapedRowMetadata={unescapedRowMetadata} dataFromExtension={dataFromExtension} />}
            {
                (!hasSimple && !hasBusiness) && <>
                    <AccordionHeaderComponent activeIndices={activeIndices} setActiveIndices={setActiveIndices} accIndex={5} accTitle="Customizations" />
                    {activeIndices.includes(5) && <CustomizationsComponent afterCreate={afterCreate} setDisableSaveButtons={setDisableSaveButtons} selectedOptionAssignee={selectedOptionAssignee} setSelectedOptionAssignee={setSelectedOptionAssignee} assigneeId={assigneeId} setFormInfo={setFormInfo} formInfo={formInfo} handleStandartInputChange={handleStandartInputChange} itemData={itemData} setItemData={setItemData} editIndex={editIndex} listInfo={listInfo} unescapedRowMetadata={unescapedRowMetadata}  bulletItems={bulletItems} setBulletItems={setBulletItems} measureItems={measureItems} setMeasureItems={setMeasureItems} measureBothItems={measureBothItems} setMeasureBothItems={setMeasureBothItems} optionsAssignee={optionsAssignee} setOptionsAssignee={setOptionsAssignee} editId={editId}/>}</>
            }
        </div>
    )
}

export default DropdownComponent;