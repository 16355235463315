import React from 'react' ;
import styles from './SettingsAccountModal.module.scss' ;

import {MoonLoader} from "react-spinners";

import {PopoverIcon, SettingsIconGearSvg} from "@assets/icons";
import Button, {ModifierType} from "../../../../../../components/UI/Button";
import Tooltip from "../../../../../../components/UI/Tooltip";

interface SettingsAccountModalUIProps {
	settingsAccountModalRef:any;
	isLoading:boolean;
	settingsAccountModalCheckers:any;
	checkedState:any;
	handleCheckboxChange:(value:string) => void;
	handleSettingsSubAccount:(lp_subaccount_settings:any) => void;
	handleToggleSettingsAccountModal:() => void;

}

const SettingsAccountModalUI = (props:SettingsAccountModalUIProps) => {
	const {settingsAccountModalRef,isLoading,settingsAccountModalCheckers,checkedState,handleCheckboxChange,handleSettingsSubAccount,handleToggleSettingsAccountModal} = props ;
	return (
	 <div className={styles['modal__overlay']}>
		 <div ref={settingsAccountModalRef} className={styles['modal']}>
			 <div className={styles['modal__header']}>
				 <SettingsIconGearSvg/>
				 <div className={styles['modal__title']}>Settings</div>
			 </div>
			 {
				 isLoading ? <div className={styles['modal__loading']}>
					 <MoonLoader size={40}/>
				 </div> : <>
					 <div className={styles['modal__wrapper']}>
						 {settingsAccountModalCheckers?.map((checker: any) => (
						  <div key={checker.id} className={`${styles['checker']} ${checker.id === 5 && checker.value === "allow_analytics_access" ? "analyticsAccessLabelWrapper" : ""}`}>
							  <input
							   type={'checkbox'}
							   id={checker.id}
							   className={styles['checker__checkbox']}
							   checked={!!checkedState[checker.value]}
							   onChange={() => handleCheckboxChange(checker.value)}
							  />
							  <label
							   htmlFor={checker.id}
							   className={`${styles['checker__label']} ${checkedState[checker.value] ? styles['checker__label--checked'] : ''}`}
							  >{checker.label}</label>
							  {checker.id === 5 && checker.value === "allow_analytics_access" &&
								  <Tooltip trigger={"mouseenter"} content={<div>
									  Allow this sub-account to use the Analytics feature and import sales. This does not grant access to view the primary account's analytics
								  </div>}>
									  <PopoverIcon style={{cursor:'pointer'}}/>
								  </Tooltip>}
						  </div>
						 ))}
					 </div>
					 <div className={styles['modal__footer']}>
						 <Button
						  modifier={ModifierType.Transparent}
						  style={{
							  color: '#767676',
							  background: '#EFEFEF'
						  }}
						  handleClick={handleToggleSettingsAccountModal}>
							 Cancel
						 </Button>
						 <Button modifier={ModifierType.Primary}
								 handleClick={() => handleSettingsSubAccount(checkedState)}>
							 Save Changes
						 </Button>
					 </div>
				 </>
			 }
		 </div>
	 </div>
	)
}

export default SettingsAccountModalUI;