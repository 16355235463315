import React from 'react'
import styles from './QuickLinks.module.scss'

import quickLinks from "@assets/constants/quickLinks";
import QuickLinkItem from "./components/QuickLinkItem";
import useRoles from "@hooks/useRoles";
import {useSelector} from "react-redux";


interface IQuickLinksProps {
}

const QuickLinks = (props: IQuickLinksProps) => {
	const {is_role_allowed_master,hasSimple,hasSubRole } = useRoles() ;

	const allowSalesAccess = useSelector((state:any) => state.navigation.allowSalesAccess)

	const filteredQuickLinks = quickLinks.filter((link: any) => {
		if (is_role_allowed_master && link.id === 1) {
			return false;
		}
		if (link.id === 3 && (hasSimple || ( (hasSubRole && allowSalesAccess) || hasSubRole))) {
			return false;
		}
		return true;
	});

	return (
	 <div className={styles.quickLinks}>
		 <div className={styles.quickLinksWrapper}>
			 {filteredQuickLinks?.map((item: any) => (
			  <QuickLinkItem
			   key={item.id}
			   icon={item.icon}
			   title={item.title}
			   link={item.link}/>
			 ))}
		 </div>

	 </div>
	)
}

export default QuickLinks