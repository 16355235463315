import React from 'react'
import styles from '../../QuickLinks.module.scss' ;

import {Link} from "react-router-dom";
import {useIntercom} from "react-use-intercom" ;


interface IQuickLinkItemProps {
	icon: any;
	title: string;
	link: string;
}

const QuickLinkItem = (props: IQuickLinkItemProps) => {
	const {title, link, icon} = props ;

	const {showMessages, showNews} = useIntercom() ;

	const handleClickLink = () => {
		if (!link && title.includes('Contact')) {
			showMessages() ;
		}
		if (!link && title.includes('News')) {
			showNews(0) ;
		}
	} ;

	const unTargetBlank = link === '/listings' || link === '/listings/add' || link === '/sales' || ( link.includes('help') && title === 'Software')  || (link.includes('account') && title === 'My Account') ;

	return (
	 link ?
	  <Link
	   to={link} target={link && !unTargetBlank ? '_blank' : ""} className={styles.quickLinkItem} onClick={handleClickLink}>
		 <span className={title !== 'Guide to LP' && title !== 'Listing Party' ? styles.menuLinks : ""}>{icon}</span>
		 <div className={styles['title']}>{title}</div>
	 </Link> :
	  <div
	   className={styles.quickLinkItem} onClick={handleClickLink}><span className={title !== 'Guide to LP' && title !== 'Listing Party' ? styles.menuLinks : ""}>{icon}</span>
		  <div className={styles['title']}>{title}</div>
	  </div>
	)
}
export default QuickLinkItem