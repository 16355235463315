export const settingsAccountModalCheckers = [
	{ id: 1, label: 'All Listing Access', value: "allow_all_listings_access" },
	{ id: 2, label: 'All Template Access', value: "allow_all_templates_access" },
	{ id: 3, label: 'Delete Listing', value: "allow_listings_deletion" },
	{ id: 4, label: 'Express Editor', value: "allow_express_editor_access" },
	{ id: 5, label: 'Analytics Access', value: "allow_analytics_access" },
	{ id: 6, label: 'Draft Listing', value: "allow_drafts_page_access" },
	{ id: 7, label: 'Description', value: "allow_description" },
	{ id: 8, label: 'Listing Party', value: "allow_listing_party_access" },
]
