import React, {useEffect} from 'react';
import ColumnSearch from '../ColumnSearch';
import {LockIcon, PopoverIcon} from "@assets/icons";
import Tippy from "@tippyjs/react";

interface ITableResizeColumProps {
 col: any;
 i: number;
 innerRef?: any;
 activeIndex?: number | null;
 mouseDown?: (index: number) => void;
 activePlatforms?: any;
 handleTogglePlatformsModalShow?: any;
 handleGetColumnSearchDebounced?: any;
 stickyColumnsListSales?:any;
 setStickyColumnsListSales?:any;
 getStickyStyle?:any;
 tippyElementStickyColumnsRefSales?:any;
}

const TableResizeColumn = (props: ITableResizeColumProps) => {
 const {
  col,
  innerRef,
  activeIndex,
  i,
  mouseDown,
  activePlatforms,
  handleTogglePlatformsModalShow,
  handleGetColumnSearchDebounced,
  stickyColumnsListSales,
  setStickyColumnsListSales,
  getStickyStyle,
  tippyElementStickyColumnsRefSales,
 } = props;

    useEffect(() => {
        try{
            const savedName = localStorage.getItem("activeColumnNameSales");
            if (savedName) {
                // Update the state based on the saved name
                setStickyColumnsListSales((prevList: any) => {
                    // Find the index of the active column
                    const activeIndex = prevList.findIndex(
                        (item: any) => item.value?.toLowerCase() === savedName.toLowerCase()
                    );
                    // Map over the list to update activeColumn and stickyColumn
                    return prevList.map((item: any, index: number) => ({
                        ...item,
                        activeColumn: index === activeIndex,
                        stickyColumn: index <= activeIndex, // Make all previous and active columns sticky
                    }));
                });
            }
        }catch(e){
            console.log(e, 'error');
        }
    }, []);

    function updateActiveColumn(name: any) {
        try {
            // Retrieve the currently active column name from localStorage
            const currentActiveColumn = localStorage.getItem("activeColumnNameSales");
            // Determine if the clicked item is already active
            const isCurrentlyActive = currentActiveColumn?.toLowerCase() === name?.toLowerCase();
            if (isCurrentlyActive) {
                // If the clicked item is already active, deactivate it
                localStorage.removeItem("activeColumnNameSales");
                setStickyColumnsListSales((prevList: any) =>
                    prevList.map((item: any) => ({
                        ...item,
                        activeColumn: false,
                        stickyColumn: false,
                    }))
                );
            } else {
                // If the clicked item is not active, activate it
                localStorage.setItem("activeColumnNameSales", name);
                setStickyColumnsListSales((prevList: any) => {
                    // Find the index of the clicked item
                    const activeIndex = prevList.findIndex(
                        (item: any) => item.value?.toLowerCase() === name?.toLowerCase()
                    );
                    // Update the stickyColumn and activeColumn properties
                    return prevList.map((item: any, index: number) => ({
                        ...item,
                        activeColumn: index === activeIndex,
                        stickyColumn: index <= activeIndex, // Set stickyColumn true for active and preceding columns
                    }));
                });
            }
        }catch(e){
            console.log(e, 'error')
        }
    }


    return (
     <>
      <th
          ref={innerRef}
          id={`${stickyColumnsListSales.some(
              (obj: any) =>
                  obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                  obj.stickyColumn
          ) ? 'sticky-image-col' : ''}`}
          style={{
              ...getStickyStyle(col.value), // Dynamic styles
              ...{
                  position: 'sticky',
                  top: '0',
                  maxWidth: col.value === 'title' ? 'none' : '500px !important',
                  display: 'flex',
                  alignItems: 'baseline',
                  justifyContent: 'flex-start',
                  flexDirection: 'column',
                  gap: '5px',
              },
          }}
          className="sticky-element sticky"
      >
       <div style={col.value === 'profit' ? {cursor: 'pointer', display:'flex', alignItems: 'center', gap: '5px'} : {cursor: 'pointer'}}>
           <>
               {
                   stickyColumnsListSales.some((obj: any) => obj.value?.toLowerCase() === col.value?.toLowerCase()) ?
                       <div className="th-inner-container">
                           <div
                               className={`lock-icon ${
                                   stickyColumnsListSales.some(
                                       (obj: any) =>
                                           obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                                           obj.activeColumn
                                   )
                                       ? 'sticky-column-active-sales' // Replace 'sticky-class' with your desired class name
                                       : ''
                               }`}
                               onClick={()=> updateActiveColumn(col.value) }
                           >
                               {
                                   !stickyColumnsListSales.some(
                                       (obj: any) =>
                                           obj.value?.toLowerCase() === col.value?.toLowerCase() &&
                                           obj.activeColumn
                                   ) ?
                                       <Tippy
                                           interactive={true}
                                           zIndex={9993}
                                           trigger="mouseenter"
                                           arrow={false}
                                           appendTo={tippyElementStickyColumnsRefSales.current || document.body}
                                           placement="bottom"
                                           content={
                                               <div>
                                                   Pin this column and all columns to its left to keep them in view while
                                                   scrolling.
                                               </div>
                                           }
                                       >
                                           <LockIcon/>
                                       </Tippy> :
                                       <Tippy
                                           interactive={true}
                                           zIndex={9993}
                                           trigger="mouseenter"
                                           arrow={false}
                                           appendTo={tippyElementStickyColumnsRefSales.current || document.body}
                                           placement="bottom"
                                           content={
                                               <div>
                                                   Click to unpin all pinned columns.
                                               </div>
                                           }
                                       >
                                           <LockIcon/>
                                       </Tippy>
                               }
                           </div>
                           <p> {col.heading} </p>
                       </div> :
                       <p> {col.heading}</p>
               }
               {
                   col.value === 'profit' &&
                   <Tippy
                       zIndex={9991}
                       trigger="click"
                       arrow={false}
                       appendTo={tippyElementStickyColumnsRefSales.current || document.body}
                       placement="bottom"
                       content={
                           <div>
                               <p>Profit Calculation: Total Sold Price - COGS - Sales Tax - Shipping Cost Seller - Fees = PROFIT</p>
                           </div>
                       }
                   >
                       <PopoverIcon
                           className={'popoverIcon'}
                           color={'#fff'}
                       ></PopoverIcon>
                   </Tippy>
               }
           </>
       </div>
       <div
           style={{
            height: '100%',
            position: 'absolute',
            zIndex: 534534,
           }}
           onMouseDown={() => mouseDown && mouseDown(i)}
           className={`resize-handle ${activeIndex === i ? 'active' : 'idle'}`}
       />
       {col.value !== 'image' &&
           col.value !== 'title' &&
           col.value !== 'total_sold_price' &&
           col.value !== 'Shop' &&
           col.value !== 'actions' &&
           col.value !== 'shop_id' &&
           col.value !== 'date_sold' &&
           col.value !== 'sku' &&
           col.value !== 'shipping_cost_buyer' &&
           col.value !== 'shipping_cost_seller' &&
           col.value !== 'cogs' &&
           col.value !== 'linked' &&
           col.value !== 'quantity' && (
               <ColumnSearch
                   activePlatforms={activePlatforms}
                   name={col.value}
                   handleTogglePlatformsModalShow={handleTogglePlatformsModalShow}
                   handleGetColumnSearchDebounced={handleGetColumnSearchDebounced}
               />
           )}
      </th>
     </>
 )


};

export default TableResizeColumn;
