import React, {useState, useEffect, useRef} from 'react';

import styles from './Header.module.scss';
import './header.css'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Tippy from "@tippyjs/react";
import { Link } from 'react-router-dom';
import toSnakeToTitle from '../../../utils/toSnakeToTitle'

import {
  Helper,
  ListingPerfectlyLogo,
  ListingPerfectlyNotActiveLogo,
  NotificationVector,
  Account,
  ListingSvg,
  ViewTemplates,
  Community,
  SalesSvg,
  SettingsIconGearSvg,
  MarketIcon,
  HeaderExpandIcon,
  HeaderStarIcon,
  HeaderStartIconNotActive,
  AddListingQuickLink,
  ExpressListerQuickLink,
  ViewListingQuickLink,
  OldCatalogQuickLink,
  AddTemplateQuickLink,
  ViewTemplatesQuickLink,
  SalesAnalyticsQuickLink,
  HelpCenterQuickLink,
  LatestVersionQuickLink,
  CurrentIssuesQuickLink,
  FaqQuickLink,
  ListingPartyQuickLink,
  ListingPartyEventsQuickLink,
  ReferralQuickLink,
  CommunityQuickLink2,
  MyAccountQuickLinkSVG,
  LogOutQuickLink,
  ExtensionQuickLink, WebsiteQuickLink, ContactUsQuickLink, SubAccountsIcon,
} from '@assets/icons';
import { useDispatch,  useSelector } from 'react-redux';
import {
  iframeLoaderChanger,
  setActivateNotificationModal, setActiveLeftMenu,
  setCandidantLinkPure,
  setIframeLink,
  setIframeName, setMenuClickedWhileSaveActive,
  setSecureModalWhenChangePage,
  setShowIframe,
  setShowQuestionModal
} from '../../../redux/slices/NavigationSlice';
import { setSettingsActiveIndex } from '../../../redux/slices/SettingsSlice';
import {useIntercom} from "react-use-intercom";
import _ from "lodash";
import ConnectedMarketplacesModal from "../../ConnectedMarketplacesModal";
import {instanceAxios} from "../../../services";
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";
import Auth from "@services/Auth";
import useRoles from "@hooks/useRoles";
export interface NavigationState {
  active: boolean;
  isBulkEdit: boolean;
  iframeLink: string;
  isDeleted: number;
  activeSaveRetain:boolean;
  isBlockedSideMenu:boolean
}

interface HeaderProps {
  routerLink: string;
  configID:string;
  quickLinks:any;
  setQuickLinks:(quickLinks:any) => void;
}
export interface RootState {
  navigation: NavigationState;
}

function Header({ routerLink,configID,quickLinks,setQuickLinks }: HeaderProps) {
  const isSmallScreenLaptop = UseIsSmallLaptop() ;
  const link = routerLink;
  const {  showMessages } = useIntercom();
  const navigate = useNavigate()
  const [quickLinkAdded,setQuickLinkAdded] = useState(false);
  const token: any = Auth.get_token();
  const {hasSimple, is_role_allowed_master, hasSubRole } = useRoles();
  const dropdownSectionsRef = useRef<Array<HTMLDivElement | null>>([]);

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const navigation = useSelector((state: RootState) => state.navigation.active);
  const settingsActiveIndex = useSelector(
   (state: any) => state.settings.settingsActiveIndex
  );
  const [isChecked, setIsChecked] = useState(false);
  const iframeLink = useSelector(
   (state: RootState) => state.navigation.iframeLink
  );
  const activeSave = useSelector(
   (state: RootState) => state.navigation.activeSaveRetain
  );
  const isDeleted = useSelector(
   (state: RootState) => state.navigation.isDeleted
  );
  const dispatch = useDispatch();
  const [notificationsLength, setNotificationsLength] = useState(0);
  const initialIndex = (routerLink === 'listings' || routerLink === 'expressLister') ? 1 : routerLink === 'settings' ? 6 :  routerLink === 'sales' ? 33 : 0;
  const [activeIndex, setActiveIndex] = useState<number | null>(initialIndex);
  const [getMarketsFromExtension, setGetMarketsFromExtension] = useState<any>(null);
  const [includesError, setIncludesError] = useState(false);
  const [hasExtensionSupport, setHasExtensionSupport] = useState(false);
  const [hasExtensionInstall, setHasExtensionInstall] = useState(false);
  const allowAllTemplatesAccess = useSelector((state: any) => state.navigation.allowAllTemplatesAccess);
  const allowSalesAccess = useSelector((state:any) => state.navigation.allowSalesAccess)
  const [connectedMarketplacesModal, setConnectedMarketplacesModal] = useState(false);
  const [scrollIndex, setScrollIndex] = useState<number | null>(null);
  const handleChangeQuickLinks = (linkObj:any) => {
    setQuickLinkAdded(true)
    const quickLink = {
      id: Date.now(),
      title: linkObj?.title,
      href: linkObj?.href,
      svg: linkObj?.svg,
      value:linkObj?.value
    }

    if(checkLinkInQuickLinks(linkObj.value)) {
      const filteredQuickLinks = (quickLinks || [])?.filter((link:any) => link.value !== linkObj.value)
      return   setQuickLinks(filteredQuickLinks)
    }


    setQuickLinks((prevQuickLinks:any) => {
      const validPrevQuickLinks = Array.isArray(prevQuickLinks) ? prevQuickLinks : [];

      const isDuplicate = validPrevQuickLinks.some((item: any) => item.value === quickLink.value);

      if (!isDuplicate) {
        return [...validPrevQuickLinks, quickLink];
      } else {
        return validPrevQuickLinks;
      }
    });
  }

  useEffect(() => {
    if(quickLinkAdded) {
      sendQuickLinks()
    }

  }, [quickLinks]);

  const sendQuickLinks = async () => {
    try {
      const payload:any = {
        filter_by:{
          id:configID
        },
        data:{
          config:{
            quick_links:[]
          }
        }
      }
      quickLinks?.forEach((link:any) => {
        payload.data.config.quick_links.push(toSnakeToTitle(link.title,'to_snake'))
      })

      const response = await instanceAxios.put('user-configs/website/update', payload)

      if(response.status === 200) {
        console.log('response')
      }

    }catch(error) {
      console.error(error)
    }
  }

  const checkForError = ()=>{
    const hasConnectionError = (getMarketsFromExtension || [])?.some((item: any) => item.hasOwnProperty('connection_status_extension_error'));
    if (hasConnectionError) {
      setIncludesError(true)
    }else{
      setIncludesError(false)
    }
  }

  useEffect(() => {
    checkForError();
  }, [getMarketsFromExtension]);

  useEffect(() => {
    const bodyElement = document.body;
    const handleAttributeChange = () => {
      const connectionWizardMarkets = bodyElement.getAttribute('lp_custom_chrome_extension_marketplaces_connected');
      try {
        if (connectionWizardMarkets) {
          // Decode HTML entities using Lodash
          setHasExtensionSupport(true);
          const decodedData = _.unescape(connectionWizardMarkets);
          const parsedArray = JSON.parse(decodedData);
          // Check if the new data is different from the current state
          if (JSON.stringify(parsedArray) !== JSON.stringify(getMarketsFromExtension)) {
            setGetMarketsFromExtension(parsedArray);
          }
        }
        else {
          setHasExtensionSupport(false);
        }
      } catch (e) {
        console.log('error', e);
      }
    };

    const observer = new MutationObserver(() => {
      handleAttributeChange();
    });

    observer.observe(bodyElement, {
      attributes: true,
      attributeFilter: ['lp_custom_chrome_extension_marketplaces_connected'],
    });

    // Initial attribute check
    handleAttributeChange();

    return () => {
      observer.disconnect();
    };
  }, [getMarketsFromExtension]);

  useEffect(() => {
    const bodyElement = document.body;
    const handleAttributeChange = () => {
      const extensionVersion = bodyElement.getAttribute('lp_custom_chrome_extension_version');
      try {
        if (extensionVersion) {
          setHasExtensionInstall(true)
        }
        else {
          setHasExtensionInstall(false)
        }
      } catch (e) {
        console.log('error', e);
      }
    };

    const observer = new MutationObserver(() => {
      handleAttributeChange();
    });

    observer.observe(bodyElement, {
      attributes: true,
      attributeFilter: ['lp_custom_chrome_extension_version'],
    });

    // Initial attribute check
    handleAttributeChange();

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleClick = (index: number, scrollIndex:number) => {
    if (activeIndex === index) {
      setActiveIndex(null); // Close the clicked accordion if it's already open
    } else {
      setActiveIndex(index);
      setScrollIndex(scrollIndex);
    }
  };

  useEffect(() => {
    try {
      if (scrollIndex !== null && dropdownSectionsRef.current[scrollIndex]) {
        dropdownSectionsRef.current[scrollIndex]?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        setScrollIndex(null);
      }
    }
    catch (e) {
      console.log(e, 'error')
    }
  }, [scrollIndex]);


  useEffect(() => {
    if(routerLink !== 'settings'){
      dispatch(setSettingsActiveIndex(null))}
  }, [link]);

  useEffect(() => {
    if (token) {
      axios
      .post(
       'https://api.listperfectly.com/v2/notifications/list',
       {
         metadata: { offset: 0, limit: 10 },
         filter_by: {
           state: 'public',
         },
       },
       {
         headers: {
           Authorization: `Bearer ${token}`,
         },
       }
      )
      .then((response) => {
        const totalNotifications = response?.data?.metadata?.total;

        // Retrieve deleted notifications from localStorage
        const deletedNotifications = JSON.parse(
         localStorage.getItem('deletedNotifications') || '[]'
        );

        // Calculate the effective notifications count
        const effectiveNotificationsLength =
         totalNotifications - deletedNotifications.length;
        setNotificationsLength(effectiveNotificationsLength);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    }
  }, []);

  const handleClickHelp = () => {
    showMessages();
  }

  const handleChangeDropdownToSettingsPage = (index: number) => {
    dispatch(
     setSettingsActiveIndex(settingsActiveIndex === index ? null : index)
    );
  };

  const handleShowConnectedMarketsModal = () => {
    setConnectedMarketplacesModal(true);
  };

  const toggleShowLeftMenu = () => {
    // close opened dropdown when close expanded menu
    setActiveIndex(null);
    if(!navigation) {
      dispatch(setActiveLeftMenu(true))
      localStorage.setItem('leftMenuExpanded', JSON.stringify(true));
    }else {
      dispatch(setActiveLeftMenu(false))
      localStorage.removeItem('leftMenuExpanded');
    }
  }
  const checkLinkInQuickLinks =(link:string) => {
    let includedLink = false ;

    quickLinks?.forEach((quickLink:any) => {
      if(quickLink.value === link) {

        includedLink = true
      }
    })
    return includedLink
  }

  const leftMenuExpanded = Boolean(localStorage.getItem('leftMenuExpanded'))

  const handleMouseEnter = () => {
    timerRef.current = setTimeout(() => {
      dispatch(setActiveLeftMenu(true));
    }, 1000);
  };

  const handleMouseLeave = () => {
    // close opened dropdown when hover over menu
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    if(!leftMenuExpanded){
      setActiveIndex(null);
    }
    if(leftMenuExpanded) return
    dispatch(setActiveLeftMenu(false))
  }

  return (
   <>
     <a
      className={`${styles.invisibleAddListingBtn } lp_custom_extension_header_add_listing_button`}
      onClick={() => {
        if (!iframeLink.length) {
          dispatch(setShowIframe());
          dispatch(setIframeName('add listing'));
          dispatch(iframeLoaderChanger(true));
          dispatch(
           setIframeLink('listperfectly.com/listings/add')
          );
        } else if(iframeLink !== 'listperfectly.com/listings/add'){
          dispatch(
           setShowQuestionModal(
            'https://app.listperfectly.com/listings/add'
           )
          );
          dispatch(
           setCandidantLinkPure(
            'listperfectly.com/listings/add'
           )
          );
        }
      }}

     ></a>
     <header className={styles.header}
     >
       <div
        className="container"
        style={{
          // width: navigation ? '210px' : '100px',
          transition:'width 0.3s ease'
        }}
       >
         <div
          className={styles.header__wrapper}
          style={{
            width: navigation ? '210px' : '61px',
            transition:'width 0.3s ease'
          }}
         >
           <div
            style={{
              // marginLeft:!navigation && quickLinks?.length > 7 ? '-13px' : '-8px',
              marginLeft:'-8px'
              // width:navigation ? '218px' : '89px',
            }}
            className={styles.logoBlock}
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleMouseEnter}
           >
             {navigation ? (
              <Link
               to="/"
               className={styles.logoLink}>
                <ListingPerfectlyLogo width={180} height={40}  />
                {/*<ChristmasLogo width={180} height={40}/>*/}
              </Link>
             ) : (
              <Link
               to="/"
               className={styles.logoLink}>
                <ListingPerfectlyNotActiveLogo width={isSmallScreenLaptop ? 32 : 40} height={isSmallScreenLaptop ? 32 : 40} />
                {/*<ChristmasLogoInactive width={isSmallScreenLaptop ? 32 : 40} height={isSmallScreenLaptop ? 32 : 40} />*/}
              </Link>
             )}
           </div>
           <div
            className="scrollContainer"
            style={{
              gap:'0',
              transition:'width 0.3s ease',
            }}
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleMouseEnter}
           >
             <div style={{
               display:'flex',
               flexDirection:'column',
               marginTop: '10px',
               width:navigation ? "185px" : '50px',

             }}
             className={'firstHeaderBlock'}
             >
               <div
                className={styles.notification}
                style={{
                  justifyContent: navigation ? 'space-between' : 'center',
                }}
                onClick={() => {
                  setIsChecked(true);
                  dispatch(setActivateNotificationModal(true));
                }}
               >
                 {!navigation && notificationsLength - isDeleted > 0 && !isChecked && (
                  <div className={styles.notificationGreenDot}></div>
                 )}
                 <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                   <NotificationVector
                    className={styles.sideMenuIcon}
                    width={17} height={20}/>
                   {navigation && <p
                    style={{
                      fontSize:isSmallScreenLaptop ? 14 : 16
                    }}
                    className="navigationText">Notifications</p>}
                 </div>

                 {navigation && notificationsLength - isDeleted > 0 && (
                  <div className={styles.circle}>
                    {notificationsLength - isDeleted}
                  </div>
                 )}
               </div>

               {(hasExtensionInstall && hasExtensionSupport) && <div
                className={`${styles.notification} lp_custom_marketplaces_connected_button`}
                style={{
                  justifyContent: navigation ? 'space-between' : 'center',
                }}
                onClick={() => handleShowConnectedMarketsModal()}
               >

                 <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                   <MarketIcon
                    className={`${styles.marketplaceIcon} ${styles.sideMenuIcon}`}/>
                   {navigation && <p
                    style={{
                      fontSize:isSmallScreenLaptop ? 14 : 16
                    }}
                    className="navigationText">Connections</p>}
                 </div>
                 {navigation && (
                  <div
                   className={`${styles.marketplaceCircle} ${(includesError || !getMarketsFromExtension.length) ? styles.red : styles.green} lp_custom_marketplaces_connected_button_status`}>
                  </div>
                 )}
               </div>}

               {(hasExtensionInstall && !hasExtensionSupport) && <Tippy
                interactive={true}
                zIndex={5}
                placement={'bottom'}
                arrow={false}
                trigger="focus click"
                content={
                 <p>
                   Activate or <a target="_blank" href={'https://help.listperfectly.com/en/articles/8913965'}>install</a> the List Perfectly extension
                 </p>
                }
               >
                 <div
                  className={`${styles.notification} ${styles.leftMenuLinkDisabled}`}

                  style={{
                    // width: !navigation ? '50px' : '',
                    justifyContent: navigation ? 'space-between' : 'center',
                  }}>

                   <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                     <MarketIcon className={`${styles.marketplaceIcon} ${styles.sideMenuIcon}`}/>
                     {navigation && <p
                      style={{
                        fontSize:isSmallScreenLaptop ? 14 : 16
                      }}
                      className="navigationText">Connections</p>}
                   </div>
                   {navigation && (
                    <div
                     style={{
                       background:'#B6B6B6'
                     }}
                     className={`${styles.marketplaceCircle} ${includesError ? styles.red : styles.green} lp_custom_marketplaces_connected_button_status`}
                    >
                    </div>
                   )}
                 </div>

               </Tippy>}

               {(!hasExtensionInstall && !hasExtensionSupport) && <Tippy
                interactive={true}
                placement={'bottom'}
                zIndex={5}
                arrow={false}
                trigger="focus click"
                content={
                  <p>
                    Activate or <a target="_blank"
                                   href={'https://help.listperfectly.com/en/articles/8913965'}>install</a> the List
                    Perfectly extension
                  </p>
                }
               >
                 <div
                  className={`${styles.notification} ${styles.leftMenuLinkDisabled}`}
                  style={{
                    // width: !navigation ? '50px' : '',
                    justifyContent: navigation ? 'space-between' : 'center',
                  }}>

                   <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                     <MarketIcon className={`${styles.marketplaceIcon} ${styles.sideMenuIcon}`}/>
                     {navigation && <p
                      style={{
                        fontSize:isSmallScreenLaptop ? 14 : 16
                      }}
                      className="navigationText">Connections</p>}
                   </div>
                   {navigation && (
                    <div
                     style={{
                       background:'#B6B6B6'
                     }}
                     className={`${styles.marketplaceCircle} ${includesError ? styles.red : styles.green} lp_custom_marketplaces_connected_button_status`}
                    >
                    </div>
                   )}
                 </div>
               </Tippy>
               }

             </div>
             {quickLinks?.length > 0 &&
              <div
               style={{
                 width: navigation ? '185px' : '50px',
               }}
              >
                <>
                  {quickLinks?.length > 0 && (
                   <div className={styles.quickLinksBlock} style={{visibility: navigation ? 'visible' : 'hidden'}}>
                     <div className={styles.quickLinksTitle}>{navigation ? 'favorites' : 't'}</div>
                     <HeaderStarIcon/>
                   </div>
                  )}
                  <div
                   className={`${styles.quickLinksContainer} quickLinksContainer`}
                  >
                    {quickLinks?.map((item: any) => {

                      if (item.title === 'Express Lister' ) {
                          return (
                           <a
                            key={item.id}
                            className={link === 'expressLister' ? styles.quickLinkATagActive : styles.quickLinkATag}
                            onClick={(e) => {
                              e.preventDefault();
                              if (activeSave) {
                                setMenuClickedWhileSaveActive(true);
                                dispatch(setSecureModalWhenChangePage(true));
                              } else {
                                navigate('/listings/express')
                              }
                            }}
                            href={item.href}
                           >
                             <div className={styles.quickLinks}
                                  style={{
                                    justifyContent: navigation ? 'flex-start' : 'center',
                                    alignItems: 'center',
                                  }}>
                               <ExpressListerQuickLink
                                className={styles.sideMenuIcon}
                               />
                               {navigation && item.title}
                             </div>
                           </a>
                          )
                      } else if (item.title === 'Add Listing') {
                        return (
                         <a
                          key={item.id}
                          className={`${styles.quickLinkATag}`}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/listings/add`);
                          }}
                          href={'/listings/add'}
                         >
                           <div
                            className={styles.quickLinks}
                            style={{
                              justifyContent: !navigation
                               ? 'center'
                               : 'flex-start',
                              alignItems: 'center'
                            }}
                           >
                             <item.svg
                             className={styles.sideMenuIcon}
                             />
                             {navigation && item.title}
                           </div>
                         </a>
                        );
                      } else if (item.title === 'Add Template') {
                        return (
                         <a
                          key={item.id}
                          className={styles.quickLinkATag}
                          onClick={(e) => {
                            e.preventDefault();
                            if (activeSave) {
                              setMenuClickedWhileSaveActive(true);
                              dispatch(setSecureModalWhenChangePage(true));
                            } else {
                              navigate('/templates/add')
                            }
                          }}
                          href={item.href}
                         >
                           <div
                            className={styles.quickLinks}
                            style={{
                              justifyContent: !navigation
                               ? 'center'
                               : 'flex-start',
                              alignItems: 'center'
                            }}>
                             <item.svg
                              className={styles.sideMenuIcon}
                             />
                             {navigation && item.title}
                           </div>
                         </a>
                        )
                      } else if (item.title === 'View Templates') {
                        return (
                         <a
                          key={item.id}
                          className={styles.quickLinkATag}
                          onClick={(e) => {
                            e.preventDefault();
                            if (activeSave) {
                              setMenuClickedWhileSaveActive(true);
                              dispatch(setSecureModalWhenChangePage(true));
                            } else {
                              navigate('/templates');
                            }
                          }}
                          href={item.href}
                         >
                           <div
                            className={styles.quickLinks}
                            style={{
                              justifyContent: !navigation
                               ? 'center'
                               : 'flex-start',
                              alignItems: 'center'
                            }}
                           >
                             <item.svg
                              className={styles.sideMenuIcon}
                             />
                             {navigation && item.title}
                           </div>
                         </a>
                        )
                      } else if (item.title === 'Contact Us') {
                        return (
                         <a
                          key={item.id}
                          className={styles.quickLinkATag}
                          onClick={handleClickHelp}
                         >
                           <div
                            className={styles.quickLinks}
                            style={{
                              justifyContent: !navigation
                               ? 'center'
                               : 'flex-start',
                              alignItems: 'center'
                            }}
                           >
                             <item.svg
                              className={styles.sideMenuIcon}
                             />
                             {navigation && item.title}
                           </div>

                         </a>
                        )
                      } else if (item.title === 'Extension') {
                        return (
                         <Link to='/settings?page=extension'
                               key={item.id}
                               className={styles.quickLinkATag}
                               onClick={(e) => {
                                 if (activeSave) {
                                   e.preventDefault();
                                   setMenuClickedWhileSaveActive(true);
                                   dispatch(setSecureModalWhenChangePage(true));
                                 } else {
                                   handleChangeDropdownToSettingsPage(1)
                                 }
                               }}>
                           <div
                            className={styles.quickLinks}
                            style={{
                              justifyContent: !navigation
                               ? 'center'
                               : 'flex-start',
                              alignItems: 'center'
                            }}>
                             <item.svg
                              className={styles.sideMenuIcon}
                             />
                             {navigation && item.title}
                           </div>
                         </Link>
                        )
                      } else if (item.title === 'Website') {
                        return (
                         <Link to='/settings?page=website'
                               key={item.id}
                               className={styles.quickLinkATag}
                               onClick={(e) => {
                                 if (activeSave) {
                                   e.preventDefault();
                                   setMenuClickedWhileSaveActive(true);
                                   dispatch(setSecureModalWhenChangePage(true));
                                 } else {
                                   handleChangeDropdownToSettingsPage(3)
                                 }
                               }}>
                           <div
                            className={styles.quickLinks}
                            style={{
                              justifyContent: !navigation
                               ? 'center'
                               : 'flex-start',
                              alignItems: 'center'
                            }}>
                             <item.svg
                              className={styles.sideMenuIcon}
                             />
                             {navigation && item.title}
                           </div>
                         </Link>
                        )
                      }  else if (item.title === 'Sub Accounts') {
                        return (
                         <Link to='/settings?page=sub-accounts'
                               key={item.id}
                               className={styles.quickLinkATag}
                               onClick={(e) => {
                                 if (activeSave) {
                                   e.preventDefault();
                                   setMenuClickedWhileSaveActive(true);
                                   dispatch(setSecureModalWhenChangePage(true));
                                 } else {
                                   handleChangeDropdownToSettingsPage(0)
                                 }
                               }}>
                           <div
                            className={styles.quickLinks}
                            style={{
                              justifyContent: !navigation
                               ? 'center'
                               : 'flex-start',
                              alignItems: 'center'
                            }}>
                             <item.svg
                              className={styles.sideMenuIcon}
                             />
                             {navigation && item.title}
                           </div>
                         </Link>
                        )
                      }

                      else {
                        if(item.value === 'sales__analytics' && ((hasSimple) )) return
                        return (
                         <a
                          key={item.id}
                          className={styles.quickLinkATag}
                          href={item.href}
                          target={"_blank"}
                          onClick={(e:any) => {
                            if(item.value === 'view_listings') {
                              e.preventDefault()
                              navigate('/listings')
                            }
                            if(item.value === 'sales__analytics') {
                              e.preventDefault();
                              if (activeSave) {
                                setMenuClickedWhileSaveActive(true);
                                dispatch(setSecureModalWhenChangePage(true));
                              } else {
                                navigate('/sales');
                              }
                            }
                          }}
                         >
                           <div
                            className={styles.quickLinks}
                            style={{
                              justifyContent: !navigation
                               ? 'center'
                               : 'flex-start',
                              alignItems: 'center'
                            }}>
                             <item.svg
                              className={styles.sideMenuIcon}
                             />
                             {navigation && item.title}
                           </div>
                         </a>
                        )
                      }
                    })}

                  </div>
                </>
              </div>
             }
             <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: navigation ? '185px' : '50px',
                userSelect: 'none',
              }}
             >
                <div className={`${styles.quickLinksTitle} ${styles.navigationTo}`} style={{visibility: navigation ? 'visible' : 'hidden'}}>{navigation ? 'NAVIGATE TO' : 'T'}</div>
               <div
                className={
                  link === 'listings' || link === 'expressLister'
                   ? 'acordionHeederActive'
                   : 'acordionHeeder'
                }
               >
                 <div
                  className="acordionFlex acordionFlexListings"
                  onClick={() => handleClick(1, 0)}
                  style={{
                    justifyContent: navigation ? 'space-between' : 'center',
                  }}
                 >
                   <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                     <ListingSvg
                     className={styles.sideMenuIcon}
                     />
                     {navigation && <div>Listings</div>}
                   </div>
                 </div>
                 {activeIndex === 1 && navigation && (
                  <div
                   className="downSection" ref={(el) => (dropdownSectionsRef.current[0] = el)}>
                      <a
                       className={`${styles.acrodionItemListingBlock} ${link === 'expressLister' && styles.acrodionItemListingBlockActive} `}
                       onClick={(e:any) => {
                         e.preventDefault();
                         if (activeSave) {
                           setMenuClickedWhileSaveActive(true);
                           dispatch(setSecureModalWhenChangePage(true));
                         } else {
                           navigate('/listings/express')
                         }
                         }}
                         href="/listings/express"
                      >
                        <div
                         className='acrodionItemListing'
                         style={{fontSize: 14}}

                        >
                          Express Lister
                        </div>
                        <div
                         className={styles.headerStartIcon}
                         onClick={(e:any) => {
                           e.stopPropagation();
                           e.preventDefault();
                           handleChangeQuickLinks({
                             href: "/listings/express",
                             title: 'Express Lister',
                             svg: ExpressListerQuickLink,
                             value: 'express_lister'
                           })
                         }}>
                          {checkLinkInQuickLinks('express_lister') ? <HeaderStarIcon/> :
                           <HeaderStartIconNotActive/>}
                        </div>

                      </a>
                    <a
                     className={styles.acrodionItemListingBlock}
                     onClick={(e) => {
                       e.preventDefault();
                       navigate(`/listings/add`);
                     }}
                     href={'/listings/add'}
                    >
                      <div
                       className="acrodionItemListing"
                       style={{
                         fontSize: 14
                       }}

                      >
                        Add Listing
                      </div>
                      <div
                       className={styles.headerStartIcon}
                       onClick={(e:any) => {
                         e.stopPropagation();
                         e.preventDefault();
                         handleChangeQuickLinks({
                           href: "",
                           title: 'Add Listing',
                           svg: AddListingQuickLink,
                           value: 'add_listing'
                         })
                       }}
                      >
                        {checkLinkInQuickLinks('add_listing') ? <HeaderStarIcon/> :
                         <HeaderStartIconNotActive/>}
                      </div>

                    </a>
                    <a
                     className={`${styles.acrodionItemListingBlock} ${link === 'listings' && styles.acrodionItemListingBlockActive}`}
                     onClick={(e) => {
                       e.preventDefault();
                       navigate('/listings')
                       dispatch(
                        setSettingsActiveIndex(null)
                       );
                     }}
                     href="/listings"
                    >
                      <div
                       className="acrodionItemListing"
                       style={{
                         fontSize: 14
                       }}
                      >
                        View listings
                      </div>
                      <div
                       className={styles.headerStartIcon}
                       onClick={(e:any) => {
                         e.stopPropagation();
                         e.preventDefault();
                         handleChangeQuickLinks({
                           href: "/listings",
                           title: "View listings",
                           svg: ViewListingQuickLink,
                           value: 'view_listings'
                         })
                       }}
                      >
                        {checkLinkInQuickLinks('view_listings') ? <HeaderStarIcon/> :
                         <HeaderStartIconNotActive/>}
                      </div>

                    </a>

                    <a
                     className={styles.acrodionItemListingBlock}
                     onClick={(e) => {
                       e.preventDefault();
                       window.open('https://app.listperfectly.com/listed-items', '_blank', 'noopener,noreferrer')
                     }}
                     href="https://app.listperfectly.com/listed-items/"
                    >
                      <div
                       className="acrodionItemListing"
                       style={{
                         fontSize: 14
                       }}
                      >
                        Old Catalog
                      </div>
                      <div
                       className={styles.headerStartIcon}
                       onClick={(e:any) => {
                         e.stopPropagation();
                         e.preventDefault();
                         handleChangeQuickLinks({
                           href: "https://app.listperfectly.com/listed-items/",
                           title: 'Old Catalog',
                           svg: OldCatalogQuickLink,
                           value: 'old_catalog'
                         })
                       }}
                      >
                        {checkLinkInQuickLinks('old_catalog') ? <HeaderStarIcon/> :
                         <HeaderStartIconNotActive/>}
                      </div>

                    </a>
                  </div>
                 )}
               </div>

               <div className={`${link === 'templates' ? 'acordionHeederActive' : 'acordionHeeder'} templatesAccordion`}>
                 {
                     (hasSubRole && allowAllTemplatesAccess) || !hasSubRole ?
                   <div
                   className="acordionFlex"
                   onClick={() => handleClick(2, 1)}
                 style={{
                   justifyContent: navigation ? 'space-between' : 'center',
                 }}
               >
                 <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                   <ViewTemplates
                       className={styles.sideMenuIcon}
                   />
                   {navigation && <div>Templates</div>}
                 </div>
               </div> :
                         <Tippy
                             interactive={true}
                             zIndex={5}
                             placement={'bottom'}
                             arrow={false}
                             trigger="focus click"
                             content={
                               <p>
                                 Request access from primary account
                               </p>
                             }
                         >
                           <div
                               className="acordionFlex accordionFlexDisabled"
                               style={{
                                 justifyContent: navigation ? 'space-between' : 'center',
                               }}
                           >
                             <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                               <ViewTemplates
                                   className={styles.sideMenuIcon}
                               />
                               {navigation && <div>Templates</div>}
                             </div>
                           </div>

                         </Tippy>
                 }

                 {activeIndex === 2 && navigation && (
                     <div
                         className="downSection"
                         style={{alignItems: !navigation ? 'center' : ''}}
                         ref={(el) => (dropdownSectionsRef.current[1] = el)}
                     >
                       {
                           ((hasSubRole && allowAllTemplatesAccess) || !hasSubRole) && <a
                               className={styles.acrodionItemListingBlock}
                               onClick={(e) => {
                                 e.preventDefault();
                                 if (activeSave) {
                                   setMenuClickedWhileSaveActive(true);
                                   dispatch(setSecureModalWhenChangePage(true));
                                 } else {
                                   navigate('/templates/add')
                                 }
                               }}
                               href="/templates/add"
                           >
                             <div
                                 className="acrodionItemListing"
                                 style={{
                                   fontSize: 14
                                 }}
                             >
                               Add Template
                             </div>
                             <div
                                 className={styles.headerStartIcon}
                                 onClick={(e: any) => {
                                   e.stopPropagation();
                                   e.preventDefault();
                                   handleChangeQuickLinks({
                                     href: "",
                                     title: 'Add Template',
                                     svg: AddTemplateQuickLink,
                                     value: 'add_template'
                                   })
                                 }}
                             >
                               {checkLinkInQuickLinks('add_template') ? <HeaderStarIcon/> :
                                   <HeaderStartIconNotActive/>}
                             </div>
                           </a>
                       }
                       <a
                           className={styles.acrodionItemListingBlock}
                           onClick={(e) => {
                             e.preventDefault();
                             if (activeSave) {
                               setMenuClickedWhileSaveActive(true);
                               dispatch(setSecureModalWhenChangePage(true));
                             } else {
                               navigate('/templates');
                             }
                           }}
                           href="/templates"
                       >
                         <div
                             className="acrodionItemListingAddTemplate"
                             style={{
                               fontSize: 14
                             }}
                         >
                           View templates
                         </div>
                         <div
                             className={styles.headerStartIcon}
                             onClick={(e: any) => {
                               e.stopPropagation();
                               e.preventDefault();
                               handleChangeQuickLinks({
                                 href: "",
                                 title: 'View Templates',
                                 svg: ViewTemplatesQuickLink,
                                 value: 'view_templates'
                               })
                             }}
                         >
                           {checkLinkInQuickLinks('view_templates') ? <HeaderStarIcon/> :
                               <HeaderStartIconNotActive/>}

                         </div>
                       </a>

                     </div>
                 )}
               </div>

               <div className={`${link === 'sales' ? 'acordionHeederActive' : 'acordionHeeder'} salesAccordion`}
                    style={{
                      display:
                          hasSimple
                              ? 'none'
                              : ''
                    }}>
                 {
                     (hasSubRole && allowSalesAccess) || !hasSubRole ?
                   <div
                   className="acordionFlex"
                   onClick={() => handleClick(33, 2)}
                 style={{justifyContent: navigation ? 'space-between' : 'center'}}
               >
                 <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                   <SalesSvg
                       className={styles.sideMenuIcon}
                   />
                   {navigation && <div>Sales</div>}
                 </div>
               </div> :
                         <Tippy
                             interactive={true}
                             zIndex={5}
                             placement={'bottom'}
                             arrow={false}
                             trigger="focus click"
                             content={
                               <p>
                                 Request access from primary account
                               </p>
                             }
                         >
                           <div
                               className="acordionFlex accordionFlexDisabled"
                               style={{
                                 justifyContent: navigation ? 'space-between' : 'center',
                               }}
                           >
                             <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                               <SalesSvg
                                   className={styles.sideMenuIcon}
                               />
                               {navigation && <div>Sales</div>}
                             </div>
                           </div>

                         </Tippy>
                 }

                 {activeIndex === 33 && navigation && (
                  <div
                   className="downSection"
                   style={{alignItems: !navigation ? 'center' : ''}}
                   ref={(el) => (dropdownSectionsRef.current[2] = el)}
                  >
                    <div>
                      <a
                       className={`${styles.acrodionItemListingBlock} ${link === 'sales' && styles.acrodionItemListingBlockActive}`}
                       onClick={(e) => {
                         e.preventDefault();
                         if (activeSave) {
                           setMenuClickedWhileSaveActive(true);
                           dispatch(setSecureModalWhenChangePage(true));
                         } else {
                           handleClick(33, 2);
                           navigate('/sales')
                         }
                         dispatch(setSettingsActiveIndex(null));
                       }
                       }
                       href="/sales"
                      >
                        <div
                         className="acrodionItemListing"
                         style={{
                           fontSize: 14
                         }}
                        >
                          Sales & Analytics
                        </div>
                        <div
                         className={styles.headerStartIcon}
                         onClick={(e:any) => {
                           e.stopPropagation();
                           e.preventDefault();
                           handleChangeQuickLinks({
                             href: '/sales',
                             title:'Sales & Analytics',
                             svg:SalesAnalyticsQuickLink,
                             value:'sales__analytics'
                           })
                         }}
                        >
                          {checkLinkInQuickLinks('sales__analytics') ? <HeaderStarIcon/> : <HeaderStartIconNotActive/>}
                        </div>
                      </a>
                    </div>
                  </div>
                 )}
               </div>

               <div className="acordionHeeder">
                 <div
                  className="acordionFlex"
                  onClick={() => handleClick(3, 3)}
                  style={{
                    justifyContent: navigation ? 'space-between' : 'center',
                  }}
                 >
                   <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                     <Helper
                     className={styles.sideMenuIcon}
                     />
                     {navigation && <div>Help & Support</div>}
                   </div>

                 </div>
                 {activeIndex === 3 && navigation && (
                  <div
                   className="downSection"
                   style={{alignItems: !navigation ? 'center' : ''}}
                   ref={(el) => (dropdownSectionsRef.current[3] = el)}
                  >
                    <div>
                      <div className={styles.acrodionItemListingBlock}
                           onClick={() => {
                             handleClickHelp()
                           }}
                      >
                        <div
                         className="acrodionItem"
                         style={{gap: '4px', fontSize: 14}}
                        >
                          Contact Us
                        </div>
                        <div
                         className={styles.headerStartIcon}
                         onClick={(e:any) => {
                           e.stopPropagation();
                           e.preventDefault();
                           handleChangeQuickLinks({
                             href: '',
                             title: 'Contact Us',
                             svg: ContactUsQuickLink,
                             value:'contact_us'
                           })
                         }}>
                          {checkLinkInQuickLinks('contact_us') ? <HeaderStarIcon/> : <HeaderStartIconNotActive/>}
                        </div>
                      </div>

                    </div>
                    <div>
                      <a
                       className={styles.acrodionItemListingBlock}
                       onClick={(e) => {
                         e.preventDefault();
                         window.open('https://help.listperfectly.com/en', '_blank', 'noopener,noreferrer')
                       }}
                       href='https://help.listperfectly.com/en/'
                      >
                        <div
                         className="acrodionItem"
                         style={{gap: '4px', fontSize: 14}}
                        >
                          Help Center
                        </div>
                        <div
                         className={styles.headerStartIcon}
                         onClick={(e:any) => {
                           e.stopPropagation();
                           e.preventDefault();
                           handleChangeQuickLinks({
                             href: 'https://help.listperfectly.com/en/',
                             title: 'Help Center',
                             svg: HelpCenterQuickLink,
                             value:'help_center'
                           })
                         }}
                        >
                          {checkLinkInQuickLinks('help_center') ? <HeaderStarIcon/> : <HeaderStartIconNotActive/>}
                        </div>

                      </a>
                    </div>

                    <div>
                      <a
                       className={styles.acrodionItemListingBlock}
                       onClick={(e) => {
                         e.preventDefault();
                         window.open('https://help.listperfectly.com/en/articles/8913965-install-list-perfectly', '_blank', 'noopener,noreferrer')
                       }}
                       href="https://help.listperfectly.com/en/articles/8913965-install-list-perfectly"
                      >
                        <div
                         style={{fontSize: 14}}
                         className="acrodionItem"
                        >
                          Latest Version
                        </div>
                        <div
                         className={styles.headerStartIcon}
                         onClick={(e:any) => {
                           e.stopPropagation();
                           e.preventDefault();
                           handleChangeQuickLinks({
                             href: 'https://help.listperfectly.com/en/articles/8913965-install-list-perfectly',
                             title: 'Latest Version',
                             svg: LatestVersionQuickLink,
                             value:'latest_version'
                           })
                         }}
                        >
                          {checkLinkInQuickLinks('latest_version') ? <HeaderStarIcon/> : <HeaderStartIconNotActive/>}
                        </div>
                      </a>

                    </div>
                    <div>
                      <a
                       className={styles.acrodionItemListingBlock}
                       onClick={(e) => {
                         e.preventDefault();
                         window.open('https://help.listperfectly.com/en/articles/9039723-current-technical-issue', '_blank', 'noopener,noreferrer')
                       }}
                       href='https://help.listperfectly.com/en/articles/9039723-current-technical-issue'
                      >
                        <div
                         style={{fontSize: 14}}
                         className="acrodionItem"
                        >
                          Current Issues
                        </div>
                        <div
                         className={styles.headerStartIcon}
                         onClick={(e:any) => {
                           e.stopPropagation();
                           e.preventDefault();
                           handleChangeQuickLinks({
                             href: 'https://help.listperfectly.com/en/articles/9039723-current-technical-issue',
                             title: 'Current Issues',
                             svg: CurrentIssuesQuickLink,
                             value:'current_issues'
                           })
                         }}
                        >
                          {checkLinkInQuickLinks('current_issues') ? <HeaderStarIcon/> : <HeaderStartIconNotActive/>}
                        </div>
                      </a>
                    </div>
                    <div>
                      <a
                       className={styles.acrodionItemListingBlock}
                       onClick={(e) => {
                         e.preventDefault();
                         window.open('https://listperfectly.com/faq', '_blank', 'noopener,noreferrer')
                       }}
                       href="https://listperfectly.com/faq"
                      >
                        <div
                         style={{fontSize: 14}}
                         className="acrodionItem"

                        >
                          FAQ
                        </div>
                        <div
                         className={styles.headerStartIcon}
                         onClick={(e:any) => {
                           e.stopPropagation();
                           e.preventDefault();
                           handleChangeQuickLinks({
                             href: 'https://listperfectly.com/faq/',
                             title: 'FAQ',
                             svg: FaqQuickLink,
                             value:'faq'
                           })
                         }}
                        >
                          {checkLinkInQuickLinks('faq') ? <HeaderStarIcon/> : <HeaderStartIconNotActive/>}
                        </div>
                      </a>

                    </div>
                  </div>
                 )}
               </div>

               <div className="acordionHeeder">
                 <div
                  className="acordionFlex"
                  onClick={() => handleClick(4, 4)}
                  style={{
                    justifyContent: navigation ? 'space-between' : 'center',
                  }}
                 >
                   <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                     <Community
                     className={styles.sideMenuIcon}
                     />
                     {navigation && <div>Community</div>}
                   </div>

                 </div>
                 {activeIndex === 4 && navigation && (
                  <div
                   className="downSection"
                   style={{alignItems: !navigation ? 'center' : ''}}
                   ref={(el) => (dropdownSectionsRef.current[4] = el)}
                  >
                    <div>
                      <a
                       className={styles.acrodionItemListingBlock}
                       onClick={(e) => {
                         e.preventDefault();
                         window.open('https://listingparty.com', '_blank', 'noopener,noreferrer')
                       }}
                       href="https://listingparty.com"
                      >
                        <div
                         style={{fontSize: 14}}
                         className="acrodionItem"
                        >
                          <p
                           style={{
                             display: 'flex',
                             alignItems: 'center',
                             gap: '10px',
                           }}
                          >
                            Listing Party
                          </p>
                        </div>
                        <div
                         className={styles.headerStartIcon}
                         onClick={(e:any) => {
                           e.stopPropagation();
                           e.preventDefault();
                           handleChangeQuickLinks({
                             href: 'https://listingparty.com/',
                             title: 'Listing Party',
                             svg: ListingPartyQuickLink,
                             value:'listing_party'
                           })
                         }}
                        >
                          {checkLinkInQuickLinks('listing_party') ? <HeaderStarIcon/> : <HeaderStartIconNotActive/>}
                        </div>
                      </a>

                    </div>
                    <div>
                      <a
                       className={styles.acrodionItemListingBlock}
                       onClick={(e) => {
                         e.preventDefault();
                         window.open('https://listingparty.com/events', '_blank', 'noopener,noreferrer')
                       }}
                       href='https://listingparty.com/events'
                      >
                        <div
                         style={{fontSize: 14}}
                         className="acrodionItem"

                        >
                          <p
                           style={{
                             display: 'flex',
                             alignItems: 'center',
                             gap: '10px',
                           }}
                          >
                            Events
                          </p>
                        </div>
                        <div
                         className={styles.headerStartIcon}
                         onClick={(e:any) => {
                           e.stopPropagation();
                           e.preventDefault();
                           handleChangeQuickLinks({
                             href: 'https://listingparty.com/events',
                             title: 'Events',
                             svg: ListingPartyEventsQuickLink,
                             value:'events'
                           })
                         }}
                        >
                          {checkLinkInQuickLinks('events') ? <HeaderStarIcon/> : <HeaderStartIconNotActive/>}
                        </div>
                      </a>

                    </div>
                    <div>
                      <a
                       className={styles.acrodionItemListingBlock}
                       onClick={(e) => {
                         e.preventDefault();
                         window.open('https://app.listperfectly.com/referral-area/?tab=resources', '_blank', 'noopener,noreferrer')
                       }}
                       href="https://app.listperfectly.com/referral-area/?tab=resources"
                      >
                        <div
                         style={{fontSize: 14}}
                         className="acrodionItem"
                        >
                          <p
                           style={{
                             display: 'flex',
                             alignItems: 'center',
                             gap: '10px',
                           }}
                          >
                            Referral
                          </p>
                        </div>
                        <div className={styles.headerStartIcon}
                             onClick={(e:any) => {
                               e.stopPropagation();
                               e.preventDefault();
                               handleChangeQuickLinks({
                                 href: 'https://app.listperfectly.com/referral-area/?tab=resources',
                                 title: 'Referral',
                                 svg: ReferralQuickLink,
                                 value:'referral'
                               })
                             }}
                        >
                          {checkLinkInQuickLinks('referral') ? <HeaderStarIcon/> : <HeaderStartIconNotActive/>}
                        </div>
                      </a>

                    </div>
                    <div>
                      <a
                       className={styles.acrodionItemListingBlock}
                       onClick={(e) => {
                         e.preventDefault();
                         window.open('https://listperfectly.com/community', '_blank', 'noopener,noreferrer')
                       }}
                       href="https://listperfectly.com/community"
                      >
                        <div
                         style={{fontSize: 14}}
                         className="acrodionItem"

                        >
                          <p
                           style={{
                             display: 'flex',
                             alignItems: 'center',
                             gap: '10px',
                           }}
                          >
                            Community
                          </p>
                        </div>
                        <div
                         className={styles.headerStartIcon}
                         onClick={(e:any) => {
                           e.stopPropagation();
                           e.preventDefault();
                           handleChangeQuickLinks({
                             href: 'https://listperfectly.com/community',
                             title: 'Community',
                             svg: CommunityQuickLink2,
                             value:'community'
                           })
                         }}
                        >
                          {checkLinkInQuickLinks('community') ? <HeaderStarIcon/> : <HeaderStartIconNotActive/>}
                        </div>
                      </a>

                    </div>
                  </div>
                 )}
               </div>
               <div className="acordionHeeder">
                 <div
                  className="acordionFlex"
                  onClick={() => handleClick(5, 5)}
                  style={{
                    justifyContent: navigation ? 'space-between' : 'center',
                  }}
                 >
                   <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                     <Account
                     className={styles.sideMenuIcon}
                     />
                     {navigation && <div>Account</div>}
                   </div>
                 </div>
                 {activeIndex === 5 && navigation && (
                  <div
                   className="downSection"
                   style={{alignItems: !navigation ? 'center' : ''}}
                   ref={(el) => (dropdownSectionsRef.current[5] = el)}
                  >
                    <div>
                      <a
                       className={styles.acrodionItemListingBlock}
                       onClick={(e) => {
                         e.preventDefault()
                         window.open('https://app.listperfectly.com/account/?action=subscriptions', '_blank', 'noopener,noreferrer')
                       }}
                       href="https://app.listperfectly.com/account/?action=subscriptions"
                      >
                        <div
                         style={{fontSize: 14}}
                         className="acrodionItem"

                        >
                          My Account
                        </div>
                        <div
                         className={styles.headerStartIcon}
                         onClick={(e:any) => {
                           e.stopPropagation();
                           e.preventDefault();
                           handleChangeQuickLinks({
                             href: 'https://app.listperfectly.com/account/?action=subscriptions',
                             title: 'My Account',
                             svg: MyAccountQuickLinkSVG,
                             value:'my_account'
                           })
                         }}>
                          {checkLinkInQuickLinks('my_account') ? <HeaderStarIcon/> : <HeaderStartIconNotActive/>}
                        </div>
                      </a>
                    </div>
                    <div>
                      <a
                       className={styles.acrodionItemListingBlock}
                       onClick={(e) => {
                         e.preventDefault();
                         window.open('https://app.listperfectly.com/my-requests', '_blank', 'noopener,noreferrer')
                       }}
                       href="https://app.listperfectly.com/my-requests"
                      >
                        <div
                         className="acrodionItem"
                         style={{whiteSpace: 'nowrap', fontSize: 14}}
                        >
                          Help Requests
                        </div>
                        <div
                         className={styles.headerStartIcon}
                         onClick={(e:any) => {
                           e.stopPropagation();
                           e.preventDefault();
                           handleChangeQuickLinks({
                             href: 'https://app.listperfectly.com/my-requests',
                             title: 'Help Requests',
                             svg: HelpCenterQuickLink,
                             value:'help_requests'
                           })
                         }}>
                          {checkLinkInQuickLinks('help_requests') ? <HeaderStarIcon/> : <HeaderStartIconNotActive/>}
                        </div>
                      </a>
                    </div>
                    <div>
                      <a
                       className={styles.acrodionItemListingBlock}
                       onClick={(e) => {
                         e.preventDefault();
                         window.open('https://app.listperfectly.com/wp-login.php?action=logout&redirect_to=%2Flogin&_wpnonce=09317016fc', '_blank', 'noopener,noreferrer')
                       }}
                       href="https://app.listperfectly.com/wp-login.php?action=logout&redirect_to=%2Flogin&_wpnonce=09317016fc"
                      >
                        <div
                         style={{fontSize: 14}}
                         className="acrodionItem"
                        >
                          Log Out
                        </div>
                        <div
                         className={styles.headerStartIcon}
                         onClick={(e:any) => {
                           e.stopPropagation();
                           e.preventDefault();
                           handleChangeQuickLinks({
                             href: 'https://app.listperfectly.com/wp-login.php?action=logout&redirect_to=%2Flogin&_wpnonce=09317016fc',
                             title: 'Log Out',
                             svg: LogOutQuickLink,
                             value:'log_out'
                           })
                         }}>
                          {checkLinkInQuickLinks('log_out') ? <HeaderStarIcon/> : <HeaderStartIconNotActive/>}
                        </div>
                      </a>
                    </div>
                  </div>
                 )}
               </div>
               <div
                className={
                  link === 'settings'
                   ? 'acordionHeederActive'
                   : 'acordionHeeder'
                }>
                 <div
                  className="acordionFlex"
                  onClick={() => {
                    handleClick(6, 6);
                  }}
                  style={{
                    justifyContent: navigation ? 'space-between' : 'center',
                  }}>
                   <div style={{display: 'flex', gap: '7px', alignItems: 'center'}}>
                     <SettingsIconGearSvg
                     className={styles.sideMenuIcon}
                     />
                     {navigation && <div className={styles.settingsNew}>Settings
                     </div>}
                   </div>
                 </div>
                 {activeIndex === 6 && navigation && (
                  <div
                   className="downSection"
                   style={{alignItems: !navigation ? 'center' : ''}}
                   ref={(el) => (dropdownSectionsRef.current[6] = el)}>
                    <a
                     style={{
                       whiteSpace: 'nowrap',
                       background:
                        settingsActiveIndex === 1 ? '#f5f5f5' : '',
                     }}
                     className={styles.acrodionItemListingBlock}
                     onClick={(e) =>{
                       e.preventDefault();
                       if (activeSave) {
                         setMenuClickedWhileSaveActive(true);
                         dispatch(setSecureModalWhenChangePage(true));
                       } else {
                         handleChangeDropdownToSettingsPage(1)
                         navigate('/settings?page=extension')
                       }
                     }}
                     href="/settings?page=extension"
                    >
                      <div
                       style={{
                         color: settingsActiveIndex === 1 ? '#2B2B2B' : '',
                         fontWeight: settingsActiveIndex === 1 ? 600 : 400,
                         fontSize: 14
                       }}
                       className="acrodionItem"
                      >
                        Extension
                      </div>
                      <div
                       className={styles.headerStartIcon}
                       onClick={(e:any)=>{
                         e.stopPropagation();
                         e.preventDefault();
                         handleChangeQuickLinks({
                           title: 'Extension',
                           href: '/settings',
                           svg: ExtensionQuickLink,
                           value:'extension'
                         })
                       }}>
                        {checkLinkInQuickLinks('extension') ? <HeaderStarIcon/> : <HeaderStartIconNotActive/>}
                      </div>
                    </a>
                    {
                     !hasSimple &&
                     <a
                      style={{
                        background:
                         settingsActiveIndex === 3 ? '#f5f5f5' : '',
                      }}
                      className={styles.acrodionItemListingBlock}
                      onClick={(e) => {
                        e.preventDefault();
                        if (activeSave) {
                          setMenuClickedWhileSaveActive(true);
                          dispatch(setSecureModalWhenChangePage(true));
                        } else {
                          handleChangeDropdownToSettingsPage(3);
                          navigate('/settings?page=website');
                        }
                      }}
                      href="/settings?page=website"
                     >
                       <div
                        style={{
                          color: settingsActiveIndex === 3 ? '#2B2B2B' : '',
                          fontWeight: settingsActiveIndex === 3 ? 600 : 400,
                          fontSize: 14
                        }}
                        className="acrodionItem"
                       >
                         Website
                       </div>
                       <div
                        className={styles.headerStartIcon}
                        onClick={(e:any) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleChangeQuickLinks({
                            title: 'Website',
                            href: '/settings',
                            svg: WebsiteQuickLink,
                            value:'website'
                          })
                        }}
                       >
                         {checkLinkInQuickLinks('website') ? <HeaderStarIcon/> : <HeaderStartIconNotActive/>}
                       </div>
                     </a>}
                    {
                     is_role_allowed_master && <a
                      style={{
                        whiteSpace: 'nowrap',
                        background:
                         settingsActiveIndex === 0 ? '#f5f5f5' : '',
                      }}
                      className={styles.acrodionItemListingBlock}
                      onClick={(e) => {
                        e.preventDefault();
                        if (activeSave) {
                          setMenuClickedWhileSaveActive(true);
                          dispatch(setSecureModalWhenChangePage(true));
                        } else {
                          handleChangeDropdownToSettingsPage(0)
                          navigate('/settings?page=sub-accounts')
                        }
                      }}
                      href="/settings?page=sub-accounts"
                     >
                       <div
                        style={{
                          color: settingsActiveIndex === 0 ? '#2B2B2B' : '',
                          fontWeight: settingsActiveIndex === 0 ? 600 : 400,
                          fontSize: 14
                        }}
                        className="acrodionItem"
                       >
                         Sub Accounts
                       </div>
                       <div
                        className={styles.headerStartIcon}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleChangeQuickLinks({
                            title: 'Sub Accounts',
                            href: '/settings',
                            svg: SubAccountsIcon,
                            value: 'sub_accounts'
                          })
                        }}>
                         {checkLinkInQuickLinks('sub_accounts') ? <HeaderStarIcon/> : <HeaderStartIconNotActive/>}
                       </div>
                     </a>
                    }
                  </div>

                 )}
               </div>

             </div>
           </div>
           <div
            style={{
              justifyContent:navigation ? 'flex-end' : '',
              width:navigation ? "185px" : '50px',
            }}
            className={styles.expandedBlock}
           >
             <div
              onClick={toggleShowLeftMenu}
              style={{
                width: !navigation && quickLinks?.length > 7 ? '47px' : '50px',
                transition:'width 0.3s ease',
                margin:navigation ? '0' : '0 auto'
              }}
              className={styles.expanded}>
               <HeaderExpandIcon
                style={{
                  transform: navigation ? 'rotate(0deg)' : 'rotate(180deg)',
                  transition:'all 0.3s ease',
                }}
               />
             </div>
           </div>
         </div>
       </div>
     </header>
     {connectedMarketplacesModal && (
      <ConnectedMarketplacesModal
       includesError={includesError}
       getMarketsFromExtension={getMarketsFromExtension}
       setConnectedMarketplacesModal={setConnectedMarketplacesModal}
      />
     )}
   </>
  );
}

export default Header;